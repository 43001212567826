import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SubscriptionPlansFeature } from 'Types';
import { FontAwesome } from 'Elements';
import { Col, Row } from 'reactstrap';

interface Props {
	subscriptionPlansFeature: SubscriptionPlansFeature;
}

export const SubscriptionPlansFeatureForm: FC<Props> = ({
	subscriptionPlansFeature
}) => {
	const { t } = useTranslation();
	return (
		<div className="border p-4" style={{ borderRadius: '8px' }}>
			<Row>
				<Col sm={12} xl={4}>
					<div className="d-flex align-items-center gap-2 mb-3">
						<FontAwesome
							icon="briefcase"
							size="lg"
							color="text-primary"
						/>
						<span className="fw-500 fs-base">{t('menu.job')}</span>
					</div>
					{subscriptionPlansFeature?.jobSetting?.jobEnabled && (
						<div className="d-flex align-items-center gap-2 mb-3">
							<FontAwesome
								icon="check"
								size="1x"
								className="flex-shrink-0 text-secondary"
							/>
							<div className="fw-400 fs-sm">
								{t('text.feature.job-enabled')}
							</div>
						</div>
					)}
					{subscriptionPlansFeature?.jobSetting
						?.publishJobEnabled && (
						<div className="d-flex align-items-center gap-2 mb-3">
							<FontAwesome
								icon="check"
								size="1x"
								className="flex-shrink-0 text-secondary"
							/>
							<div className="fw-400 fs-sm">
								{t('text.feature.publish-job-enabled')}
								{` (${t('label.max')}: ${
									subscriptionPlansFeature?.jobSetting
										?.maxPublishJobs
										? subscriptionPlansFeature?.jobSetting
												?.maxPublishJobs
										: t('label.unlimited')
								})`}
							</div>
						</div>
					)}
					{subscriptionPlansFeature?.jobSetting?.jobsGoogleIndex && (
						<div className="d-flex align-items-center gap-2 mb-3">
							<FontAwesome
								icon="check"
								size="1x"
								className="flex-shrink-0 text-secondary"
							/>
							<div className="fw-400 fs-sm">
								{t('text.feature.jobs-google-index')}
							</div>
						</div>
					)}
					{subscriptionPlansFeature?.jobSetting
						?.limitJobVisibility && (
						<div className="d-flex align-items-center gap-2 mb-3">
							<FontAwesome
								icon="check"
								size="1x"
								className="flex-shrink-0 text-secondary"
							/>
							<div className="fw-400 fs-sm">
								{t('text.feature.limit-job-visibility')}
							</div>
						</div>
					)}
				</Col>
				<Col sm={12} xl={4}>
					<div className="d-flex align-items-center gap-2 mb-3">
						<FontAwesome
							icon="chalkboard-user"
							size="lg"
							color="text-primary"
						/>
						<span className="fw-500 fs-base">
							{t('text.internship')}
						</span>
					</div>
					{subscriptionPlansFeature?.internshipSetting
						?.internshipEnabled && (
						<div className="d-flex align-items-center gap-2 mb-3">
							<FontAwesome
								icon="check"
								size="1x"
								className="flex-shrink-0 text-secondary"
							/>
							<div className="fw-400 fs-sm">
								{t('text.feature.internship-enabled')}
							</div>
						</div>
					)}
					{subscriptionPlansFeature?.internshipSetting
						?.publishInternshipEnabled && (
						<div className="d-flex align-items-center gap-2 mb-3">
							<FontAwesome
								icon="check"
								size="1x"
								className="flex-shrink-0 text-secondary"
							/>
							<div className="fw-400 fs-sm">
								{t('text.feature.publish-internship-enabled')}
								{` (${t('label.max')}: ${
									subscriptionPlansFeature?.internshipSetting
										?.maxPublishInternships
										? subscriptionPlansFeature
												?.internshipSetting
												?.maxPublishInternships
										: t('label.unlimited')
								})`}
							</div>
						</div>
					)}
					{subscriptionPlansFeature?.internshipSetting
						?.internshipManagerEnabled && (
						<div className="d-flex align-items-center gap-2 mb-3">
							<FontAwesome
								icon="check"
								size="1x"
								className="flex-shrink-0 text-secondary"
							/>
							<div className="fw-400 fs-sm">
								{t('text.feature.internship-manager-enabled')}
							</div>
						</div>
					)}
				</Col>
				<Col sm={12} xl={4}>
					<div className="d-flex align-items-center gap-2 mb-3">
						<FontAwesome
							icon="memo-circle-info"
							size="lg"
							color="text-primary"
						/>
						<span className="fw-500 fs-base">
							{t('title.other')}
						</span>
					</div>
					{subscriptionPlansFeature?.eventSetting?.eventsEnabled && (
						<div className="d-flex align-items-center gap-2 mb-3">
							<FontAwesome
								icon="check"
								size="1x"
								className="flex-shrink-0 text-secondary"
							/>
							<div className="fw-400 fs-sm">
								{t('text.feature.events-enabled')}
							</div>
						</div>
					)}
					{subscriptionPlansFeature?.eventSetting
						?.publishEventsEnabled && (
						<div className="d-flex align-items-center gap-2 mb-3">
							<FontAwesome
								icon="check"
								size="1x"
								className="flex-shrink-0 text-secondary"
							/>
							<div className="fw-400 fs-sm">
								{t('text.feature.publish-events-enabled')}
								{` (${t('label.max')}: ${
									subscriptionPlansFeature?.eventSetting
										?.maxPublishEvents
										? subscriptionPlansFeature?.eventSetting
												?.maxPublishEvents
										: t('label.unlimited')
								})`}
							</div>
						</div>
					)}
					{subscriptionPlansFeature?.userAccountSetting
						?.maxUserAccounts && (
						<div className="d-flex align-items-center gap-2 mb-3">
							<FontAwesome
								icon="check"
								size="1x"
								className="flex-shrink-0 text-secondary"
							/>
							<div className="fw-400 fs-sm">
								{t('text.feature.user-accounts')}
								{` (${t('label.max')}: ${
									subscriptionPlansFeature?.userAccountSetting
										?.maxUserAccounts
										? subscriptionPlansFeature
												?.userAccountSetting
												?.maxUserAccounts
										: t('label.unlimited')
								})`}
							</div>
						</div>
					)}
				</Col>
			</Row>
		</div>
	);
};
