import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors, ModalProps } from 'Types';
import { Button, ModalBox, ModalBoxFooter, FontAwesome } from 'Elements';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styles from 'partials/step/step-container.module.scss';
import { currencyFormatter } from 'src/helpers/Utils';
import { usePaymentMethodList } from 'src/hooks/useBusinessPartner';
import { useAxios } from 'src/hooks/useAxios';
import { api } from 'src/helpers/API';
import { config } from 'src/helpers/Config';

interface Props extends ModalProps {
	totalAmount: number;
	subscriptionDuration: number;
	subscriptionPaymentStatus: number;
	urlBack: string;
	subscriptionId: string;
	returnUrl: string;
	currentSubscriptionPaymentId: string;
	toggleModalResulte: () => void;
	setSubscriptionPaymentStatus: (value: number) => void;
	refetch?: () => void;
}

export const ModalBoxPaymentMethodList: FC<Props> = ({
	isOpen,
	totalAmount,
	urlBack,
	returnUrl,
	subscriptionId,
	subscriptionDuration,
	subscriptionPaymentStatus,
	currentSubscriptionPaymentId,
	toggleModalResulte,
	setSubscriptionPaymentStatus,
	onClose,
	refetch
}) => {
	const { t } = useTranslation();
	const [_searchParams, setSearchParams] = useSearchParams();
	const { sendRequest: subscriptionPaymentsDetail } = useAxios<any>();
	const { sendRequest, isLoading } = useAxios<any>();
	const [disabled, setDisabled] = useState(false);
	const [intervalDelay, setIntervalDelay] = useState(5000);
	const { data: paymentMethodList, isLoading: isLoadingPaymentMethodList } =
		usePaymentMethodList({
			enabled: isOpen
		});
	const navigate = useNavigate();

	const onClickAddPayment = async () => {
		const url: any = `${config.redirectUrl}${returnUrl}`;
		setDisabled(true);
		await sendRequest(
			api.addPaymentMethod,
			{ data: { returnUrl: url } },
			async (data: any) => {
				window.location = data;
			}
		);
	};

	const onClickPayment = async (paymentMethodId) => {
		onClose?.();
		setDisabled(true);
		setSearchParams({
			showModal: 'true',
			subscriptionPaymentStatus: '3',
			subscriptionId: subscriptionId
		});
		toggleModalResulte();
		await sendRequest(
			api.subscriptionPay(currentSubscriptionPaymentId),
			{ data: { paymentMethodId: paymentMethodId } },
			async () => {
				setSubscriptionPaymentStatus(3);
				// checkPayStatus();

			}
		)
			.catch(() => {
				toggleModalResulte();
			})
			.finally(() => {
				setDisabled(true);
			});
	};

	const checkPayStatus = async () => {
		if (currentSubscriptionPaymentId) {
			await subscriptionPaymentsDetail(
				api.getSubscriptionPaymentsDetail(currentSubscriptionPaymentId),
				{ method: 'GET' },
				(data) => {
					if (data?.status) {
						setSubscriptionPaymentStatus(data?.status);
					}
				}
			);
		}
	};

	useEffect(() => {
		if (subscriptionPaymentStatus !== -1) {
			const intervalId = setInterval(() => {
				setIntervalDelay((prevDelay) => prevDelay + 2000);
				// setRetryNumber((prevDelay) => prevDelay + 1);
				checkPayStatus();
			}, intervalDelay);
			if (subscriptionPaymentStatus !== 3) {
				refetch && refetch();
				clearInterval(intervalId);
			}
			return () => clearInterval(intervalId);
		} else {
			if (isOpen) {
				checkPayStatus();
			}
		}
	}, [
		subscriptionPaymentStatus,
		intervalDelay,
		currentSubscriptionPaymentId
	]);

	return (
		<ModalBox isOpen={isOpen} size="lg">
			<div className="d-flex align-items-center gap-2 mb-4">
				<div>
					<FontAwesome
						icon={'credit-card'}
						className={'text-primary'}
						size="2x"
						swapOpacity
					/>
				</div>
				<div>
					<span className="fw-500 fs-base">
						{t('text.choose-your-payment-method')}
					</span>
				</div>
			</div>
			<div>
				<div className="d-flex flex-column">
					<div className="d-flex flex-column gap-1">
						<span className="fs-sm fw-400">
							{t('text.total-price')}
						</span>
						<span className="fs-small fw-400">
							{t(
								'text.with-this-subscription-you-can-support-other-community-partners'
							)}
						</span>
					</div>
					<div className="my-4 pb-3 border-bottom">
						<div className="d-flex">
							<div className="d-flex flex-column text-end pe-3 me-3 border-end border-2 text-primary">
								<h5 className="h5 mb-0">
									{currencyFormatter(totalAmount)}
								</h5>

								<span className="fw-300">
									{t('title.excluded-tax')}
								</span>
							</div>
							<div className="text-gray-3 text-start d-flex flex-column">
								<span className="fw-300 lh-base">
									{t('title.per')}
								</span>
								<span className="mt-auto fs-base">
									{`${subscriptionDuration ?? ''} ${t(
										'title.days'
									)}`}
								</span>
							</div>
						</div>
					</div>
					<div className="d-flex flex-column gap-4">
						{!isLoadingPaymentMethodList &&
							paymentMethodList?.map((item) => {
								return (
									<div
										key={item.paymentMethodId}
										className={
											isLoading || disabled
												? styles.disabledCardPayment
												: styles.cardPayment
										}>
										<div className="d-flex align-items-center justify-content-between">
											<div className="d-flex flex-column gap-2">
												<div className="d-flex align-items-center gap-2">
													<FontAwesome
														icon={'credit-card'}
														className={
															isLoading ||
															disabled
																? 'text-gray-2'
																: 'text-primary'
														}
														size="1x"
														swapOpacity
													/>
													<span
														className={`fs-5 fw-500 ${
															isLoading ||
															disabled
																? 'text-gray-2'
																: 'text-gray-4'
														}`}>
														{`${item.title}  ${
															item.last4 ?? ''
														}`}
													</span>
												</div>
												<div className="mt-2">
													{item.expYear &&
														item.expYear !==
															'0' && (
															<span
																className={`fw-400 fs-sm 	${
																	isLoading ||
																	disabled
																		? 'text-gray-2'
																		: 'text-gray-3'
																} mx-3`}>
																{`Expired ${item.expMonth}/${item.expYear}`}
															</span>
														)}
													{item.expYear === '0' && (
														<span className="fw-400 fs-sm text-gray-3 mx-3">
															{item.email}
														</span>
													)}
												</div>
											</div>
											<div>
												<Button
													onClick={() => {
														if (
															!disabled &&
															!isLoading
														) {
															onClickPayment(
																item.paymentMethodId
															);
														}
													}}
													color={
														disabled || isLoading
															? Colors['gray-2']
															: Colors[
																	'white-primary'
															  ]
													}>
													{t('button.pay-with-this')}
												</Button>
											</div>
										</div>
									</div>
								);
							})}
						{isLoadingPaymentMethodList &&
							[1, 2, 3].map((item) => {
								return (
									<div
										key={item}
										className={
											styles.cardPaymentSkeleton
										}></div>
								);
							})}
						{!isLoadingPaymentMethodList && (
							<div
								className={
									disabled || isLoading
										? styles.disabledCardPayment
										: styles.addCardPayment
								}
								onClick={onClickAddPayment}>
								<div className="d-flex align-items-center gap-2">
									<FontAwesome
										icon="plus"
										className={
											disabled || isLoading
												? 'text-gray-2'
												: 'text-primary'
										}
										size="1x"
										swapOpacity
									/>
									<span
										className={`fs-5 fw-500  ${
											disabled || isLoading
												? 'text-gray-2'
												: 'text-primary'
										}`}>
										{t('button.add-payment-method')}
									</span>
								</div>

								<div className="mt-2">
									<span
										className={`fw-400 fs-sm mx-3 ${
											disabled || isLoading
												? 'text-gray-2'
												: 'text-gray-3'
										}`}>
										{t('button.add-new-payment-method')}
									</span>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			<ModalBoxFooter className="pt-3 d-flex justify-content-center border-top mt-4">
				<Button
					color={Colors['white-red']}
					label={t('button.cancel') as string}
					onClick={() => {
						navigate(urlBack);
						refetch && refetch();
						setTimeout(() => onClose?.(), 400);
					}}
				/>
			</ModalBoxFooter>
		</ModalBox>
	);
};
