import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { useFormContext } from 'react-hook-form';
import { Colors, SubscriptionPlanDetails } from 'Types';
import {
	FontAwesome,
	Card,
	LoadingSpinner,
	SwitchController,
	SubscriptionPlansFeatureForm,
	Button
} from 'Elements';
import styled from 'styled-components';
import { useSubscriptionPlansFeature } from 'src/hooks/useSubscription';
import styles from './subscription-plan-features.module.scss';
import { clx, currencyFormatter } from 'src/helpers/Utils';
import { PlanListDrawer } from './PlanListDrawer';
import { useSelector } from 'src/hooks/useRedux';
import { useParams } from 'react-router-dom';
import { useAxios } from 'src/hooks/useAxios';
import { api } from 'src/helpers/API';

const CardBox = styled(Card)`
	min-height: 286px;
`;

export const SubscriptionPlanFeatures: FC = () => {
	const { t } = useTranslation();
	const [list, setList] = useState<any>([]);
	const { sendRequest, isLoading } = useAxios<any>();
	const { subscriptionPlan_id } = useParams();
	const { assetConfig } = useSelector((state) => state.marketing);
	const [isOpen, setIsOpen] = useState(false);
	const {
		register,
		watch,
		control,
		setValue,
		formState: { errors }
	} = useFormContext<SubscriptionPlanDetails>();

	// usePrompt(t('forms.leave-screen-massage'), isDirty);

	const valueConfig = {
		shouldDirty: true,
		shouldValidate: true
	};

	const { data: subscriptionPlansFeature, isLoading: isLoadingPlansFeature } =
		useSubscriptionPlansFeature(
			watch('subscriptionPlanFeatureId') as string,
			watch('id') as string,
			{
				enabled: !!watch('subscriptionPlanFeatureId')
			}
		);

	const onAddPlan = (arr) => {
		setValue('availableUpgradePlanIds', arr, valueConfig);
		setIsOpen(false);
	};

	const getPlanData = () => {
		if (assetConfig?.communityPartnerId) {
			sendRequest(
				api.getPlansCommunityPartner(assetConfig?.communityPartnerId),
				{ method: 'GET' },
				(planData) => {
					const newList: any = [];
					planData?.forEach((element) => {
						if (
							element.id !== subscriptionPlan_id &&
							element?.price >= watch('price')
						) {
							newList.push(element);
						}
					});
					setList(newList);
				}
			);
		}
	};

	useEffect(() => {
		if (assetConfig?.communityPartnerId) {
			getPlanData();
		}
	}, [assetConfig?.communityPartnerId]);

	const checkedPlan = (item) => {
		let check = false;
		watch('availableUpgradePlanIds')?.forEach((element) => {
			if (element === item.id) {
				check = true;
			}
		});
		return check;
	};

	const onRemovePlan = (item) => {
		const newList: any = [];
		watch('availableUpgradePlanIds')?.forEach((element) => {
			if (element !== item.id) {
				newList.push(element);
			}
		});
		setValue('availableUpgradePlanIds', newList, valueConfig);
	};

	return (
		<>
			<Row>
				<Col sm={12} xl={6} xxl={6} className="mb-4">
					<CardBox className="h-100">
						<Row>
							<Col sm={12}>
								<div className="mb-3">
									<span className="fs-sm fw-500">
										{t('text.capacity')}
									</span>
								</div>
								<FormGroup>
									<Label htmlFor="publishedJobs">
										{t('forms.max-job-capacity')}
										<small className="ms-1">
											({t('validation.required')})
										</small>
									</Label>
									<input
										{...register('publishedJobs')}
										type="text"
										id="max-job-capacity"
										aria-invalid={!!errors.publishedJobs}
										className="inputbox w-100"
									/>
									{errors.publishedJobs && (
										<div className="invalid-feedback d-block">
											{errors.publishedJobs.message}
										</div>
									)}
								</FormGroup>
								<FormGroup>
									<Label htmlFor="publishedInternships">
										{t('forms.max-internship-capacity')}
										<small className="ms-1">
											({t('validation.required')})
										</small>
									</Label>
									<input
										{...register('publishedInternships')}
										type="text"
										id="publishedInternships"
										aria-invalid={
											!!errors.publishedInternships
										}
										className="inputbox w-100"
									/>
									{errors.publishedInternships && (
										<div className="invalid-feedback d-block">
											{
												errors.publishedInternships
													.message
											}
										</div>
									)}
								</FormGroup>
								<div className={clx(styles.boxMessage, 'mt-4')}>
									<FontAwesome icon="circle-info" size="lg" />
									<div>{t('text.message-capacity')}</div>
								</div>
							</Col>
						</Row>
					</CardBox>
				</Col>
				<Col sm={12} xl={6} xxl={6} className="mb-4">
					<CardBox className="h-100">
						<Row>
							<div className="mb-3 d-flex align-items-center justify-content-between">
								<span className="fs-sm fw-500">
									{t('text.upgrade')}
								</span>
								<FormGroup>
									<SwitchController
										name="upgradeable"
										control={control}
										onClick={(e) => {
											if (e === false) {
												setValue(
													'availableUpgradePlanIds',
													[],
													valueConfig
												);
											}
										}}
										label="label.upgradable"
									/>
								</FormGroup>
							</div>
							<Col sm={12}>
								<div className={styles.boxMessage}>
									<FontAwesome icon="circle-info" size="lg" />
									<div>{t('text.message-upgrade')}</div>
								</div>
								<div className="fs-sm fw-500 my-3">
									{t('label.upgradable-to')}:
								</div>
								{list?.map((item) => {
									if (checkedPlan(item)) {
										return (
											<div
												key={item.id}
												className={clx(
													'mb-3',
													styles.cardUpgradable,
													!watch('upgradeable') &&
														styles.disabledCard
												)}>
												<div className="d-flex flex-column gap-3">
													<div className="d-flex justify-content-between gap-4 align-items-start">
														<div className="d-flex flex-column gap-2">
															<span className="fs-base fw-500">
																{item.name}
															</span>
															<div
																className={clx(
																	'text-gray-3 fs-sm fw-400 mt-1',
																	styles.description
																)}
																style={{
																	whiteSpace:
																		'pre-wrap'
																}}
																dangerouslySetInnerHTML={{
																	__html:
																		item.shortDescription ??
																		''
																}}
															/>
														</div>
														<div className="d-flex align-items-start gap-4">
															<div className="d-flex flex-column mt-4">
																<div className="d-flex gap-2 text-primary">
																	<h5 className="h5 mb-0 fw-500 fs-5 lh-base">
																		{currencyFormatter(
																			item?.price ??
																				0
																		)}
																	</h5>
																</div>
																<div className="text-gray-3 text-start d-flex gap-1 text-primary">
																	<span className="fw-500 fs-sm lh-base text-primary">
																		{`${t(
																			'title.per'
																		)}
																		
																		${item?.durationDays ?? ''}`}
																	</span>
																</div>
																<div>
																	<span className="fw-500 fs-sm lh-base text-primary">
																		{t(
																			'title.days'
																		)}
																	</span>
																</div>
															</div>
															<Button
																color={
																	Colors[
																		'white-red'
																	]
																}
																onClick={() => {
																	onRemovePlan(
																		item
																	);
																}}
																className="py-2 px-2">
																<FontAwesome
																	icon="trash-xmark"
																	size="lg"
																/>
															</Button>{' '}
														</div>
													</div>
													{/* <div className="d-flex justify-content-between gap-2 align-items-center">
														
													</div> */}
												</div>
											</div>
										);
									}
								})}
								<div className="d-flex flex-column gap-2 mb-3">
									{isLoading &&
										[1, 2, 3].map((item) => {
											return (
												<div
													key={item}
													className={
														styles.cardPlanSkeleton
													}></div>
											);
										})}
								</div>
								<div
									className={clx(
										styles.addCardUpgradable,
										!watch('upgradeable') &&
											styles.disabledCard
									)}
									onClick={() => {
										if (watch('upgradeable')) {
											setIsOpen(
												(prevState) => !prevState
											);
										}
									}}>
									<div className="d-flex align-items-center gap-3">
										<FontAwesome
											icon="plus"
											className={'text-primary'}
											size="1x"
											swapOpacity
										/>
										<span
											className={`fs-5 fw-500  ${'text-primary'}`}>
											{t('button.add-plan')}
										</span>
									</div>
									<div className="mt-2">
										<span
											className={`fw-400 fs-sm mx-3 ${'text-gray-3'}`}>
											{t(
												'button.add-plan-from-your-subscription-plans'
											)}
										</span>
									</div>
								</div>
							</Col>
						</Row>
					</CardBox>
				</Col>
			</Row>
			<Row>
				<Col>
					<CardBox className="h-100">
						<Row className="mt-4">
							{isLoadingPlansFeature && <LoadingSpinner />}
							{!isLoadingPlansFeature &&
								subscriptionPlansFeature && (
									<>
										<span className="fs-sm fw-500 mb-3">
											{t('text.other-features')}
										</span>
										<SubscriptionPlansFeatureForm
											subscriptionPlansFeature={
												subscriptionPlansFeature
											}
										/>
									</>
								)}
						</Row>
					</CardBox>
				</Col>
			</Row>
			<PlanListDrawer
				isOpen={isOpen}
				list={list}
				isLoading={isLoading}
				availableUpgradePlanIds={watch('availableUpgradePlanIds')}
				toggleDrawer={() => {
					setIsOpen((prevState) => !prevState);
				}}
				onAdd={onAddPlan}
			/>
		</>
	);
};

export default SubscriptionPlanFeatures;
