import { FC, useEffect, useState } from 'react';
import { Col, Row, Tooltip } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
	useDelayUnmount,
	useModal,
	useSubscriptionPayments,
	useUpdateSubscription
} from 'Hooks';
import {
	clx,
	currencyFormatter,
	formatLocaleDateTime,
	getRemainingDay,
	subStatus
} from 'Utils';
import {
	Badge,
	Button,
	LoadingContent,
	ModalBoxPartnerApprovalFeedback,
	ModalBoxSwitchAgentList,
	SwitchController,
	FontAwesome,
	ModalBoxPaymentMethodList,
	ModalBoxPaymentResult
} from 'Elements';
import {
	AgentPartnerApprovalStatus,
	ColorValues,
	Colors,
	PaymentStatus,
	SubscriptionDetails,
	SubscriptionStatus
} from 'Types';
import styles from 'partials/card/subscription-card.module.scss';

interface Props {
	subscription: SubscriptionDetails;
	subscriptionId: string;
	subscriptionPayments?: any;
	isLoading?: boolean;
	canAutoRenew?: boolean;
	communityPartnerInfo?: any;
	showPrice?: boolean;
}

export const DetailsSubscription: FC<Props> = ({
	subscription,
	subscriptionPayments,
	subscriptionId,
	isLoading,
	communityPartnerInfo,
	canAutoRenew = true,
	showPrice = true
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const [tooltipOpen, setTooltipOpen] = useState(false);
	const [openModalSwitch, toggleSwitchModal] = useModal();
	const [openModalFeedback, toggleFeedbackModal] = useModal();
	const [openModalResulte, toggleModalResulte] = useModal();
	const subscriptionPaymentStatusUrl =
		parseInt(searchParams.get('subscriptionPaymentStatus') as string) ?? -1;
	const [subscriptionPaymentStatus, setSubscriptionPaymentStatus] = useState(
		subscriptionPaymentStatusUrl ?? -1
	);
	const shouldRenderModalSwitch = useDelayUnmount(openModalSwitch, 350);
	const shouldRenderModalReason = useDelayUnmount(openModalFeedback, 350);
	const status = subscription?.status;
	const showModal = searchParams.get('showModal') === 'true' ? true : false;
	const [openModal, toggleModal] = useModal();
	const agentApprovedStatus = subscription?.agentApprovedStatus;
	const agentApprovalDescription =
		subscription?.agentApprovalStatus?.description ?? '';
	const canAutoRenewAndSwitch =
		canAutoRenew && status === SubscriptionStatus.active;
	const canSwitchAgent =
		canAutoRenewAndSwitch &&
		agentApprovedStatus !== AgentPartnerApprovalStatus.pending;
	const isRejected =
		agentApprovedStatus === AgentPartnerApprovalStatus.rejected;
	const isApproved =
		agentApprovedStatus === AgentPartnerApprovalStatus.approved;
	const row_class = 'justify-content-between justify-content-md-start';
	const defaultImage = '/assets/img/business-partner-default.png';

	const { updateSubscription, refetch } = useUpdateSubscription(
		subscriptionId as string,
		{ enabled: !!subscriptionId && canAutoRenew }
	);

	const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

	const getStatusData = () => {
		return subStatus.find((elem) => elem.value === status);
	};

	const { reset, control } = useForm();

	const handleUpdate = async (autoRenewal: boolean) => {
		await updateSubscription(autoRenewal, undefined, () =>
			reset({
				autoRenewal: subscription?.autoRenewal ?? true
			})
		);
	};

	const handleSwitchAgent = () => {
		toggleSwitchModal();
		void refetch();
	};

	const handleViewSwitchedSub = () => {
		navigate(`/subscriptions/${subscription.subCompanySubscription.id}`, {
			state: { prevPath: `/subscriptions/${subscription.id}` }
		});
	};

	useEffect(() => {
		reset({
			autoRenewal: subscription?.autoRenewal
		});
	}, [reset, subscription]);

	const onClickPay = () => {
		setSearchParams({ showModal: 'true' });
		toggleModal();
	};

	useEffect(() => {
		if (
			showModal &&
			subscription?.status === SubscriptionStatus.pendingPayment
		) {
			if (subscriptionPaymentStatusUrl === PaymentStatus.InProcess) {
				setSubscriptionPaymentStatus(subscriptionPaymentStatusUrl);
				toggleModalResulte();
			} else {
				toggleModal();
			}
		}
	}, [subscription, showModal]);

	const { data: subscriptionPaymentsUpgrade } = useSubscriptionPayments(
		subscription?.upgradeSubscriptionPaymentId ?? '',
		{
			enabled: !!subscription?.upgradeSubscriptionPaymentId
		}
	);

	return (
		<Row>
			<Col
				md={12}
				xl={
					canAutoRenewAndSwitch ||
					(subscription?.status === 3 &&
						(subscriptionPayments?.status ===
							PaymentStatus.Pending ||
							subscriptionPayments?.status ===
								PaymentStatus.Failed))
						? 9
						: 12
				}>
				<Row>
					<Col md={5}>
						<div className={clx('d-flex', styles.row, row_class)}>
							<span className={styles.rowLabel}>
								{t('label.name')}:
							</span>
							<div className="d-flex align-items-center gap-2">
								<span className="text-primary fs-6 fw-700">
									{subscription?.subscriptionPlanInfo?.name ??
										'---'}
								</span>
							</div>
						</div>
						<div className={clx(styles.row, row_class)}>
							<span className={styles.rowLabel}>
								{t('label.subscription-code')}:
							</span>
							<span>
								{subscription?.subscriptionPlanInfo
									?.subscriptionPlanCode ?? '---'}
							</span>
						</div>
						{showPrice && (
							<div className={clx(styles.row, row_class)}>
								<span className={styles.rowLabel}>
									{t('label.price')}:
								</span>
								<span className="fw-500">
									{currencyFormatter(
										subscriptionPayments?.price
											?.totalAmount ?? 0
									)}
								</span>
							</div>
						)}
						<div className={clx(styles.row, row_class)}>
							<span className={styles.rowLabel}>
								{t('label.status')}:
							</span>
							<Badge
								color={getStatusData()?.color ?? Colors.muted}>
								{t(`${getStatusData()?.label}`)}
							</Badge>
						</div>
						<div className={clx(styles.row, row_class)}>
							<span className={styles.rowLabel}>
								{t('label.subscription-type')}:
							</span>
							{subscription?.subscriptionPlanInfo?.type === 1 && (
								<div>
									<Badge
										color={Colors.premium}
										className="gap-2">
										<FontAwesome icon={'medal'} size="lg" />
										{t('button.premium')}
									</Badge>
								</div>
							)}
							{subscription?.subscriptionPlanInfo?.type !== 1 && (
								<div>
									<Badge
										color={Colors.association}
										className="gap-2">
										{t('button.standard')}
									</Badge>
								</div>
							)}
						</div>
					</Col>
					<Col md={7}>
						<div className={clx(styles.row, row_class)}>
							<span
								className={clx(
									styles.rowLabel,
									'fs-sm fw-400 color-gray-4'
								)}>
								{t('label.purchase-date')}:
							</span>
							<span>
								{subscription?.startDate
									? formatLocaleDateTime(
											subscription?.startDate
									  )
									: '----'}
							</span>
						</div>
						<div className={clx(styles.row, row_class)}>
							<span
								className={clx(
									styles.rowLabel,
									'fs-sm fw-500 color-gray-4'
								)}>
								{status === SubscriptionStatus.active &&
									(subscription?.autoRenewal
										? t('title.renew-at')
										: t('title.expire-on'))}
								{(status ===
									SubscriptionStatus.pendingApproval ||
									status ===
										SubscriptionStatus.pendingPayment ||
									status === SubscriptionStatus.expired) &&
									t('title.expire-on')}
								{status === SubscriptionStatus.expired &&
									t('title.switch-on')}
							</span>
							<span className="fw-500">
								{status === SubscriptionStatus.active &&
									(subscription?.renewalDate
										? `${formatLocaleDateTime(
												subscription?.renewalDate,
												false
										  )} (${getRemainingDay(
												new Date(),
												new Date(
													subscription?.renewalDate
												)
										  )} ${t('label.days')})`
										: `${formatLocaleDateTime(
												subscription?.endDate,
												false
										  )} (${getRemainingDay(
												new Date(),
												new Date(subscription?.endDate)
										  )} ${t('label.days')})`)}
								{(status ===
									SubscriptionStatus.pendingPayment ||
									status === SubscriptionStatus.expired) &&
									formatLocaleDateTime(
										subscription?.endDate,
										false
									)}
								{/* {status === SubscriptionStatus.expired &&
										formatLocaleDateTime(
											subscription?.status.updatedAt,
											false
										)} */}
							</span>
						</div>
						<div
							className={clx(
								styles.row,
								row_class,
								canSwitchAgent && 'gap-2'
							)}>
							<span className={styles.rowLabel}>
								{t('title.community-partner')}:
							</span>
							{communityPartnerInfo && (
								<div className="d-flex align-items-center flex-grow-1 justify-content-end justify-content-md-start gap-2">
									{/* <img
										src={
											communityPartnerInfo.logo ||
											defaultImage
										}
										alt={
											communityPartnerInfo.organizationName ??
											'---'
										}
										className={clx(
											styles.logo,
											styles.logoSm
										)}
									/> */}
									<FontAwesome icon="apartment" size="lg" />
									<span className="fs-sm fw-400">
										{communityPartnerInfo.organizationName ??
											'---'}
									</span>
									{communityPartnerInfo?.commissionGroup
										?.IsPremium && (
										<Badge
											color={Colors.premium}
											className="gap-2">
											<FontAwesome
												icon={'medal'}
												size="lg"
											/>
											{
												communityPartnerInfo
													?.commissionGroup.label
											}
										</Badge>
									)}
									{agentApprovedStatus ===
										AgentPartnerApprovalStatus.pending && (
										<Badge color={Colors.warning}>
											{t('badge.pending-approval')}
										</Badge>
									)}
									{isRejected && (
										<Badge color={Colors.red}>
											{t('badge.rejected')}
										</Badge>
									)}
									{agentApprovalDescription &&
										(isApproved || isRejected) && (
											<Button
												color={Colors.empty}
												className="p-0"
												id="bp-approval"
												onClick={toggleFeedbackModal}>
												<FontAwesome
													icon="info-circle"
													color={
														isApproved
															? ColorValues.green
															: ColorValues.red
													}
													size="lg"
												/>
												<Tooltip
													toggle={toggleTooltip}
													isOpen={tooltipOpen}
													placement="top"
													target="bp-approval">
													{t(
														'title.business-partner-approval-explanation'
													)}
												</Tooltip>
											</Button>
										)}
								</div>
							)}
							{/* {communityPartnerInfo?.commissionGroup?.IsPremium &&
							status === SubscriptionStatus.active
								? ''
								: (canSwitchAgent || isRejected) &&
								  status !== 4 && (
										<Button
											size="sm"
											color={Colors['white-primary']}
											label={
												t(
													'button.switch-agent'
												) as string
											}
											onClick={toggleSwitchModal}
										/>
								  )} */}
						</div>
						{status === SubscriptionStatus.expired &&
							subscription?.subCompanySubscription && (
								<div
									className={clx(
										styles.row,
										row_class,
										'gap-2 gap-xxl-4'
									)}>
									<div className="d-flex align-items-center">
										<span className={styles.rowLabel}>
											{t('title.switched-agent')}:
										</span>
										<div className="d-flex align-items-center justify-content-end justify-content-md-start flex-grow-1 flex-wrap gap-2">
											<img
												src={
													subscription
														.subCompanySubscription
														.communityPartnerInfo
														.logo || defaultImage
												}
												alt={
													subscription
														.subCompanySubscription
														.communityPartnerInfo
														.organizationName ??
													'---'
												}
												className={clx(
													styles.logo,
													styles.logoSm
												)}
											/>
											<span className="fs-sm fw-400">
												{subscription
													.subCompanySubscription
													.communityPartnerInfo
													.organizationName ?? '---'}
											</span>
										</div>
									</div>
									<Button
										size="sm"
										color={Colors['white-primary']}
										onClick={handleViewSwitchedSub}>
										<FontAwesome
											icon="eye"
											size="lg"
											className="me-2 flex-shrink-0"
										/>
										{t('button.view-sub')}
									</Button>
								</div>
							)}
					</Col>
				</Row>
			</Col>
			<Col md={12} xl={3} className="mt-4 mt-md-4 mt-xl-0">
				{canAutoRenewAndSwitch && (
					<div className="mb-4">
						<div className={clx(styles.row, 'border-0 gap-2')}>
							<span className={styles.rowLabel}>
								{t('forms.auto-renewal')}:
							</span>
							<SwitchController
								control={control}
								name="autoRenewal"
								label={
									subscription?.autoRenewal
										? 'label.active'
										: 'label.inactive'
								}
								onSwitchChange={handleUpdate}
							/>
						</div>
						<p className="mt-2 mb-0 text-gray-3 lh-md fs-small">
							{t('text.auto-renewal-terms')}
						</p>
					</div>
				)}
				{subscription?.status === 3 &&
					(subscriptionPayments?.status === PaymentStatus.Pending ||
						subscriptionPayments?.status ===
							PaymentStatus.Failed) && (
						<div className="d-flex justify-content-end">
							<div className="d-flex flex-column gap-2">
								<span>
									{t(
										'text.you-can-pay-your-subscription-invoice'
									)}
								</span>
								<div className="d-flex justify-content-end">
									<Button
										onClick={() => {
											onClickPay();
										}}
										size="sm"
										className="px-3 text-white bg-primary"
										label={
											t('button.pay-invoice') as string
										}
									/>
								</div>
							</div>
						</div>
					)}
			</Col>
			{isLoading && <LoadingContent />}
			{shouldRenderModalSwitch && (
				<ModalBoxSwitchAgentList
					isOpen={openModalSwitch}
					onClose={toggleSwitchModal}
					subscriptionId={subscriptionId}
					onSelectAgent={handleSwitchAgent}
				/>
			)}
			{shouldRenderModalReason && (
				<ModalBoxPartnerApprovalFeedback
					isOpen={openModalFeedback}
					onClose={toggleFeedbackModal}
					description={subscription.agentApprovalStatus.description}
				/>
			)}
			<ModalBoxPaymentMethodList
				subscriptionId={subscriptionId}
				urlBack={`/subscriptions/${subscriptionId}?`}
				returnUrl={`subscriptions/${subscriptionId}?showModal=true&`}
				totalAmount={
					subscriptionPaymentsUpgrade?.price?.totalAmount
						? subscriptionPaymentsUpgrade?.price?.totalAmount
						: subscriptionPayments?.price?.totalAmount
						? subscriptionPayments?.price?.totalAmount
						: 0
				}
				subscriptionDuration={
					subscriptionPaymentsUpgrade?.price?.subscriptionDuration
						? subscriptionPaymentsUpgrade?.price
								?.subscriptionDuration
						: subscriptionPayments?.price?.subscriptionDuration
				}
				currentSubscriptionPaymentId={
					subscription?.upgradeSubscriptionPaymentId
						? subscription?.upgradeSubscriptionPaymentId
						: subscription?.currentSubscriptionPaymentId
				}
				refetch={refetch}
				isOpen={openModal}
				toggleModalResulte={toggleModalResulte}
				onClose={toggleModal}
				subscriptionPaymentStatus={subscriptionPaymentStatus}
				setSubscriptionPaymentStatus={setSubscriptionPaymentStatus}
			/>
			<ModalBoxPaymentResult
				subscriptionId={subscription?.id}
				subscriptionCalculate={{
					totalAmount: subscriptionPayments?.price?.totalAmount
						? subscriptionPayments?.price?.totalAmount
						: 0,
					subscriptionDuration:
						subscriptionPayments?.price?.subscriptionDuration
				}}
				subscriptionPaymentStatus={subscriptionPaymentStatus}
				isOpen={openModalResulte}
				onClose={toggleModalResulte}
			/>
		</Row>
	);
};
