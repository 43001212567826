import { memo, FC, useState, startTransition } from 'react';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { names } from 'Constants';
import { useModal } from 'Hooks';
import { ApplicantApproveStatus, ApplicantDemand, Colors } from 'Types';
import {
	clx,
	formatLocaleDateTime,
	getFilterByValue,
	requestStatus
} from 'Utils';
import {
	Badge,
	Button,
	Card,
	FontAwesome,
	LoadingContent,
	ModalBoxRequestDescription,
} from 'Elements';
import styles from 'partials/decoration/request-decoration.module.scss';

interface Props {
	request?: ApplicantDemand;
	isLoading: boolean;
}

type DemandActionType = 'approve' | 'reject';

export const RequestDecoration: FC<Props> = memo(({ request, isLoading }) => {
	const { t } = useTranslation();
	const { jobs } = names;
	const [openModal, toggleModal] = useModal();
	// const defaultImage = '/assets/img/profiles/img-user-default.png';
	const applicantInfo = request?.applicantInfo;
	const status = request?.status as ApplicantApproveStatus;
	const applicantName = applicantInfo
		? `${applicantInfo?.firstName} ${applicantInfo?.lastName}`
		: '---';

	const [selectedType, setSelectedType] =
		useState<DemandActionType>('approve');

	const onAction: (type: DemandActionType) => void = (type) => {
		setSelectedType(type);
		startTransition(() => {
			toggleModal();
		});
	};

	return (
		<Card>
			<Row>
				<Col md={4} className="mb-3 mb-md-0">
					<div className="d-flex align-items-center gap-3">
						{/* <div className={styles.avatarBox}>
							<ShimmerImage
								width={100}
								height={100}
								src={applicant?.imageThumbnail || defaultImage}
								alt={applicantName}
								defaultImage={defaultImage}
								className={styles.avatar}
							/>
						</div> */}
						<div className="flex-grow-1 d-flex flex-column gap-2">
							<h6 className="fs-base fw-400 lh-1 mb-0">
								{applicantName ?? '---'}
							</h6>
							<span className="text-gray-3 lh-base">
								{applicantInfo?.email ?? '---'}
							</span>
							<div className="d-flex">
								<Badge
									color={
										getFilterByValue(requestStatus, status)
											?.color ?? Colors.primary
									}>
									{!isLoading
										? t(
												getFilterByValue(
													requestStatus,
													status
												)?.label as string
										  )
										: '...'}
								</Badge>
							</div>
						</div>
					</div>
				</Col>
				<Col md={8}>
					<Row>
						<Col
							md={8}
							xl={9}
							className="mb-3 mb-md-0 text-truncate">
							{/* <h6 className="fs-6 fw-400 lh-base mb-0 text-truncate">
								<Link
									to={`${jobs.path}/${request?.jobId}`}
									state={{ prevPath: request?.id }}
									title={t('title.job.view-job-details')}>
									{request?.title ?? '---'}
									{request?.code && (
										<span className="text-gray-3 fs-small fw-300 ms-2">
											({request.code})
										</span>
									)}
								</Link>
							</h6> */}
							<div className="d-flex pt-2 flex-column">
								<div className="d-flex align-items-center lh-base me-2 mb-2">
									<span className="text-gray-3">
										{t('forms.request-time')}:
									</span>
									<span className="ms-1 text-gray-5">
										{request?.createdAt
											? formatLocaleDateTime(
													request?.createdAt
											  )
											: '---'}
									</span>
								</div>
								<div className="d-flex align-items-center lh-base">
									<span className="text-gray-3">
										{status ===
										ApplicantApproveStatus.Cancelled
											? t('forms.canceled-time')
											: t('forms.update-time')}
										:
									</span>
									<span className="ms-1 text-gray-5">
										{request?.updatedAt
											? formatLocaleDateTime(
													request
														?.updatedAt
											  )
											: '---'}
									</span>
								</div>
							</div>
						</Col>
						{status !==
							ApplicantApproveStatus.Cancelled && (
							<Col md={4} xl={3}>
								<div className="d-flex align-items-center align-items-md-stretch justify-content-between justify-content-md-center flex-md-column flex-wrap">
									{status !==
										ApplicantApproveStatus.Approved && (
										<Button
											color={Colors['white-green']}
											className={clx(
												styles.submit,
												'mx-1 gap-2'
											)}
											onClick={() => onAction('approve')}>
											<FontAwesome
												icon="check"
												size="lg"
											/>
											{t('button.approve')}
										</Button>
									)}
									{status !==
										ApplicantApproveStatus.Rejected && (
										<Button
											color={Colors['white-red']}
											className={clx(
												styles.submit,
												'mx-1 gap-2'
											)}
											onClick={() => onAction('reject')}>
											<FontAwesome
												icon="xmark"
												size="lg"
												swapOpacity
											/>
											{t('button.reject')}
										</Button>
									)}
								</div>
							</Col>
						)}
					</Row>
				</Col>
				{status !== ApplicantApproveStatus.Cancelled && (
					<ModalBoxRequestDescription
						isOpen={openModal}
						onClose={toggleModal}
						type={selectedType}
						title={
							selectedType === 'approve'
								? 'title.approve-request'
								: 'title.reject-request'
						}
					/>
				)}
			</Row>
			{isLoading && <LoadingContent />}
		</Card>
	);
});

RequestDecoration.displayName = 'RequestDecoration';
