import { useTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import { Colors } from 'Types';
import {
	SectionHeadline,
	LoadingSpinner,
	ProfileAddNewCard,
	ReactSwitch,
	ModalBoxSectionEditProfile,
	FontAwesome,
	Button,
	Collapsible,
	Carousel,
	ProfileDepartmentCard
} from 'Elements';
import { Autoplay, EffectFade, Pagination, Thumbs } from 'swiper/modules';
import styles from 'partials/homepage/section/community-partner-department-section.module.scss';
import { useModal } from 'src/hooks/useModal';
import { useResponsive } from 'src/hooks/useResponsive';
import { useState } from 'react';
import { useDepartments } from 'src/hooks/useDepartment';
import { clx } from 'src/helpers/Utils';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useNavigate } from 'react-router-dom';

type Props = {
	isLoadingProfile: boolean;
	isFetchingProfile: boolean;
	onChangeValue: (
		objKey: string,
		key: string,
		value: string | boolean
	) => void;
	departments: {
		enabled: boolean;
		headline: string;
	};
};

export const CommunityPartnerDepartmentSection = ({
	isLoadingProfile,
	onChangeValue,
	departments
}: Props) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [openModal, toggleModal] = useModal();
	const navigationUrl = '/departments/create';
	const { isDesktopAndBelow } = useResponsive();
	const [thumbsSwiper, setThumbsSwiper] = useState<any>();
	const { data, isLoading } = useDepartments({
		pageSize: 20,
		pageNumber: 1,
		keyword: ''
	});

	const handleNavigate = () => {
		navigate(navigationUrl, { state: { prevPath: '/homepage' } });
	};

	return (
		<section className="position-relative mb-5">
			<Container>
				{isLoadingProfile && isLoading && (
					<div className="d-flex align-items-center justify-content-center">
						<LoadingSpinner />
					</div>
				)}
				{!isLoadingProfile && !isLoading && (
					<Collapsible
						header={
							<div
								className={`d-flex flex-wrap gap-3 align-items-center justify-content-between mb-3 ${styles.switcherBox}`}>
								<div className="d-flex flex-wrap align-items-center gap-3">
									<h5 className="fs-5 fw-700 d-flex align-items-center">
										{' '}
										{t('pages.departments')}
									</h5>
									<ReactSwitch
										size="md"
										checked={departments?.enabled}
										onChange={(val: boolean) => {
											onChangeValue(
												'departments',
												'enabled',
												val
											);
										}}
									/>
									<span className="d-flex align-items-center mb-0 py-0 cursor-pointer">
										{t('forms.show-departments-in-home')}
									</span>
								</div>
								<div>
									<Button
										size="sm"
										color={Colors['white-gray']}
										onClick={() => toggleModal()}>
										<FontAwesome
											icon="circle-info"
											size="1x"
											className="text-gray me-1"
										/>
										{t('button.more')}
									</Button>
								</div>
							</div>
						}>
						<SectionHeadline
							title={departments?.headline}
							titlePlaceholder={`[${t(
								'placeholder.our-departments'
							)}]`}
							titleColor="text-gray-5"
							className="mx-auto text-center"
							onUpdateTitle={(val) =>
								onChangeValue('departments', 'headline', val)
							}
						/>
						{data && data?.totalItems === 0 && departments && (
							<ProfileAddNewCard
								navigationUrl={navigationUrl}
								title="title.add-new-department"
							/>
						)}
						{data && data?.totalItems > 0 && (
							<div className={styles.boxSwiper}>
								<Carousel
									loop
									effect="fade"
									followFinger={false}
									direction={
										isDesktopAndBelow
											? 'horizontal'
											: 'vertical'
									}
									slidesPerView={1}
									speed={800}
									autoplay={{
										delay: 3500,
										pauseOnMouseEnter: true,
										disableOnInteraction: false
									}}
									autoHeight={isDesktopAndBelow}
									pagination={{
										enabled: data.totalItems > 1,
										clickable: true,
										horizontalClass: `${styles.bullets}`,
										verticalClass: `${styles.bullets}`,
										bulletClass: `${styles.bullet} department-bullet`,
										bulletActiveClass: `${styles.active}`
									}}
									modules={[
										Pagination,
										EffectFade,
										Autoplay,
										Thumbs
									]}
									thumbs={{ swiper: thumbsSwiper }}
									className={clx(
										styles.carousel,
										'department-container'
									)}
									enabled={data.totalItems > 1}>
									{data.items
										?.reverse()
										?.map((department) => (
											<SwiperSlide
												key={department.id}
												className={styles.slide}>
												<ProfileDepartmentCard
													preview={false}
													key={department.id}
													department={department}
												/>
											</SwiperSlide>
										))}
								</Carousel>
								<Swiper
									onSwiper={setThumbsSwiper}
									loop
									spaceBetween={10}
									slidesPerView={5}
									freeMode={true}
									watchSlidesProgress={true}
									modules={[
										Pagination,
										EffectFade,
										Autoplay,
										Thumbs
									]}>
									{data.items?.map((department) => (
										<SwiperSlide
											key={department?.id}
											className={styles?.boxThumbs}>
											<img
												src={
													department?.image?.thumbnail
												}
											/>
											<div
												className={clx(
													'boxTitleThumbs',
													styles.boxTitleThumbs
												)}>
												{department?.title}
											</div>
										</SwiperSlide>
									))}
								</Swiper>
							</div>
						)}
						{data && data?.totalItems > 0 && (
							<div className="d-flex align-items-center justify-content-center mt-4 mt-md-5">
								<Button
									size="lg"
									className="gap-3 rounded-pill"
									onClick={handleNavigate}>
									<FontAwesome
										icon="plus"
										size="xl"
										swapOpacity
									/>
									{t('title.add-new-department')}
								</Button>
							</div>
						)}
					</Collapsible>
				)}

				<ModalBoxSectionEditProfile
					isOpen={openModal}
					onClose={toggleModal}
					title="info.departments-title"
					description="info.departments-description"
				/>
			</Container>
			{!departments?.enabled && (
				<div className="overlay overlay_white overlay-box" />
			)}
		</section>
	);
};
