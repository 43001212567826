import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import {
	Button,
	FontAwesome,
	FormInlineCheckbox,
	SubscriptionCommunityPartnerCard
} from 'src/containers/Elements';
import { api } from 'src/helpers/API';
import { clx } from 'src/helpers/Utils';
import { useAxios } from 'src/hooks/useAxios';
import { useSelector } from 'src/hooks/useRedux';
import { Colors } from 'src/types/Color';
import { SubscriptionCommunityPartner } from 'src/types/Subscription';
import styles from 'partials/shared/page.module.scss';

export default function ReferralCommunityPartner({
	showSubscription = true
}: any) {
	const navigate = useNavigate();
	const { assetConfig } = useSelector((state) => state.marketing);
	const [referralCommunityPartnerId, setReferralCommunityPartnerId] =
		useState<boolean>(false);
	const [selectedCP, setSelectedCP] =
		useState<SubscriptionCommunityPartner>();
	const { t } = useTranslation();
	const { sendRequest } = useAxios();
	const getCommunityPartner = () => {
		sendRequest(
			api.getCommunityPartner(
				assetConfig?.referralCommunityPartnerId as string
			),
			{ method: 'GET' },
			(data) => {
				setSelectedCP(data as any);
			}
		);
	};
	useEffect(() => {
		if (
			assetConfig?.referralCommunityPartnerId &&
			localStorage.getItem(
				`showReferralCommunityPartnerId${assetConfig?.referralCommunityPartnerId}`
			)
		) {
			if (
				localStorage.getItem(
					`showReferralCommunityPartnerId${assetConfig?.referralCommunityPartnerId}`
				) === 'true'
			) {
				getCommunityPartner();
				setReferralCommunityPartnerId(true);
				localStorage.setItem(
					`showReferralCommunityPartnerId${assetConfig?.referralCommunityPartnerId}`,
					'true'
				);
			} else {
				setReferralCommunityPartnerId(false);
				localStorage.setItem(
					`showReferralCommunityPartnerId${assetConfig?.referralCommunityPartnerId}`,
					'false'
				);
			}
		} else if (assetConfig?.referralCommunityPartnerId) {
			getCommunityPartner();
			setReferralCommunityPartnerId(true);
			localStorage.setItem(
				`showReferralCommunityPartnerId${assetConfig?.referralCommunityPartnerId}`,
				'true'
			);
		}
	}, [assetConfig?.referralCommunityPartnerId]);

	const handleSelectAgent = (
		communityPartner: SubscriptionCommunityPartner
	) => {
		navigate(
			`/subscriptions/add?step=step2&communityPartnerId=${communityPartner?.id}`
		);
	};
	return (
		<div className="d-flex flex-column text-center gap-2 mb-5">
			{referralCommunityPartnerId && (
				<div
					style={{
						borderRadius: '16px',
						padding: '16px',
						marginBottom: '57px',
						background: '#FFFFFF'
					}}>
					<div className="d-flex justify-content-between align-items-center">
						<div className="d-flex align-items-center gap-2 text-primary">
							<FontAwesome
								icon="bolt"
								color="#86B650"
								size="lg"
							/>
							<span className="fs-5 fw-500">
								{t('label.suggestion')}
							</span>
						</div>
						<div className="d-flex align-items-center gap-3">
							<FormInlineCheckbox
								className="d-flex text-center"
								label={
									t(
										'label.dont-show-me-this-suggestion-again'
									) as string
								}
								onChange={(isChecked) => {
									if (isChecked) {
										localStorage.setItem(
											`showReferralCommunityPartnerId${assetConfig?.referralCommunityPartnerId}`,
											'false'
										);
									} else {
										localStorage.setItem(
											`showReferralCommunityPartnerId${assetConfig?.referralCommunityPartnerId}`,
											'true'
										);
									}
								}}
							/>
							<Button
								color={Colors['white-red']}
								className="gap-2"
								onClick={() => {
									setReferralCommunityPartnerId(false);
								}}>
								<FontAwesome icon="close" size="lg" />
								{t('button.close')}
							</Button>
						</div>
					</div>
					<div
						style={{
							margin: '24px 0px',
							background: '#FFEACC',
							borderRadius: '8px',
							padding: '16px',
							textAlign: 'justify'
						}}>
						<span
							className="fw-500 fs-sm"
							style={{
								color: '#9F8C27'
							}}>
							{t('label.message_suggestion')}
						</span>
					</div>
					<div
						style={{
							border: '1px solid #DBDBDB',
							borderRadius: '16px',
							padding: '4px'
						}}>
						<SubscriptionCommunityPartnerCard
							key={assetConfig?.referralCommunityPartnerId}
							notShadow={true}
							communityPartner={
								selectedCP as SubscriptionCommunityPartner
							}
							selected={false}
							onSelect={handleSelectAgent}
						/>
					</div>
				</div>
			)}
			{showSubscription && (
				<>
					<span className="fw-500 fs-6">
						{t('text.you-havent-subscription')}
					</span>
					<p className="fw-400 fs-small">
						{t(
							'text.please-informed-that-publish-your-jobs-business-partner-you-must-complete-steps-your-registration-selecting-your-subscription-plans'
						)}
					</p>
					{!referralCommunityPartnerId && (
						<Link
							to="/subscriptions/add"
							state={{
								prevPath: 'subscriptions'
							}}
							className={clx(
								'm-auto',
								styles.action,
								styles.action_lg,
								styles.action_primary
							)}>
							<FontAwesome
								icon="plus"
								className="me-2"
								size="lg"
								swapOpacity
							/>
							{t('button.add-subscription')}
						</Link>
					)}
				</>
			)}
		</div>
	);
}
