import { Badge, Button, Card, FontAwesome } from 'Elements';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { Job, JobFormData, JobVisibilitySetting } from 'src/types/Job';
import styles from './locations.module.scss';
import { Col, Row, Tooltip } from 'reactstrap';
import { useState } from 'react';
import { LocationListDrawer } from './LocationListDrawer';
import { Colors } from 'src/types/Color';
import { useFormContext } from 'react-hook-form';
import { clx, formatLocalAddress } from 'src/helpers/Utils';

function Locations() {
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = useState(false);
	const fields = useOutletContext<Job>();
	const [tooltipOpen, setTooltipOpen] = useState(false);
	const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

	const valueConfig = {
		shouldDirty: true,
		shouldValidate: true
	};

	const { setValue, watch } = useFormContext<JobVisibilitySetting>();

	const onAddLocation = (arr) => {
		const newList: any = [];
		arr.forEach((element) => {
			newList.push({
				postalCode:
					element?.address?.postalCode ??
					(element?.postalCode as string),
				address: element?.address?.street
					? formatLocalAddress(
							element?.address?.street,
							element?.address?.houseNumber,
							element?.address?.postalCode,
							element?.address?.city,
							element?.address?.state
					  )
					: element?.address,
				primary: false,
				regionCode: element?.regionCode as number,
				title: element?.name as string,
				name: element?.name as string,
				locationPoint: {
					longitude: element?.address?.location?.longitude as number,
					latitude: element?.address?.location?.latitude as number
				}
			});
		});
		setValue('jobLocations', newList, valueConfig);
		setIsOpen(false);
	};

	const onRemoveLocation = (item) => {
		const newList: any = [];
		watch('jobLocations')?.forEach((element) => {
			if (element.name !== item.name) {
				newList.push(element);
			}
		});
		setValue('jobLocations', newList, valueConfig);
	};

	return (
		<Card className="w-100">
			<div className="d-flex align-items-center gap-2">
				<h1 className="fs-5 fw-500">{t('label.locations')}</h1>
				<Button color={Colors.empty} className="p-0" id="tooltip">
					<FontAwesome icon="circle-info" />
					<Tooltip
						toggle={toggleTooltip}
						isOpen={tooltipOpen}
						placement="bottom"
						target="tooltip">
						{t('text.description-locations')}
					</Tooltip>
				</Button>
			</div>
			<Row>
				{fields?.locations?.map((item, index) => {
					return item.primary ? (
						<Col
							sm={12}
							xl={4}
							xxl={4}
							key={index}
							className="h-100">
							<div className={clx('mb-3', styles.cardLocation)}>
								<div className="d-flex justify-content-between align-items-center mb-3">
									<span className="fs-base fw-500">
										{item.title}
									</span>
									{item.primary ? (
										<Badge color={Colors.primary}>
											{t('text.primary')}
										</Badge>
									) : (
										<Button
											color={Colors['white-red']}
											onClick={() => {
												onRemoveLocation(item);
											}}
											className="py-2 px-2">
											<FontAwesome
												icon="trash-xmark"
												size="lg"
											/>
										</Button>
									)}
								</div>
								<div className="d-flex align-items-center gap-2">
									<FontAwesome
										icon="location-dot"
										size="sm"
										color="text-primary"
									/>
									<span>{item.address}</span>
								</div>
							</div>
						</Col>
					) : (
						<></>
					);
				})}
				{watch('jobLocations')?.map((item, index) => {
					return (
						<Col
							sm={12}
							xl={4}
							xxl={4}
							key={index}
							className="h-100">
							<div className={clx('mb-3', styles.cardLocation)}>
								<div className="d-flex justify-content-between align-items-center mb-3">
									<span className="fs-base fw-500">
										{item.title}
									</span>
									{item.primary ? (
										<Badge color={Colors.primary}>
											{t('text.primary')}
										</Badge>
									) : (
										<Button
											color={Colors['white-red']}
											onClick={() => {
												onRemoveLocation(item);
											}}
											className="py-2 px-2">
											<FontAwesome
												icon="trash-xmark"
												size="lg"
											/>
										</Button>
									)}
								</div>
								<div className="d-flex align-items-center gap-2">
									<FontAwesome
										icon="location-dot"
										size="sm"
										color="text-primary"
									/>
									<span>{item?.address}</span>
								</div>
							</div>
						</Col>
					);
				})}
				<Col sm={12} xl={4} xxl={4} className="h-100">
					<div
						className={clx('mb-3', styles.addCardLocation)}
						onClick={() => setIsOpen((prevState) => !prevState)}>
						<div className="d-flex align-items-center gap-3">
							<FontAwesome
								icon="plus"
								className={'text-primary'}
								size="1x"
								swapOpacity
							/>
							<span className={`fs-5 fw-500  ${'text-primary'}`}>
								{t('button.add-available-location')}
							</span>
						</div>
						<div className="mt-2">
							<span
								className={`fw-400 fs-sm mx-3 ${'text-gray-3'}`}>
								{t(
									'button.add-available-location-from-your-locations'
								)}
							</span>
						</div>
					</div>
				</Col>
			</Row>
			<LocationListDrawer
				isOpen={isOpen}
				locations={watch('jobLocations')}
				toggleDrawer={() => {
					setIsOpen((prevState) => !prevState);
				}}
				onAdd={onAddLocation}
			/>
		</Card>
	);
}

export default Locations;
