import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Button,
	Card,
	FontAwesome,
	LoadingContent,
	NoData
} from 'src/containers/Elements';
import styles from './job-partner.module.scss';
import { Colors } from 'src/types/Color';
import FindPartners from '../components/FindPartners';
import { useSubscriptions } from 'src/hooks/useSubscription';
import {
	SubscriptionPlanType,
	SubscriptionStatus
} from 'src/types/Subscription';
import { useGeneralJobUsage } from 'src/hooks/useJob';
import { Job, JobKind } from 'src/types/Job';
import useGetJobKind from '../hooks/useGetJobKind';
import { useOutletContext } from 'react-router-dom';
// import { useFormContext } from 'react-hook-form';
// import { watch } from 'fs/promises';
function JobPartners() {
	const { t } = useTranslation();
	const defaultImage = '/assets/img/default-image.png';
	const [findPartner, setFindPartner] = useState(false);
	const fields = useOutletContext<Job>();

	// const { setValue, getValues, watch } = useFormContext();
	const {
		data: subscriptions,
		isFetching,
		isSuccess
	} = useSubscriptions(
		{
			pageSize: 200,
			pageNumber: 1,
			keyword: '',
			subscriptionPlanType: SubscriptionPlanType.BuiltIn
		},
		{
			onSuccess: () => {
				refetch();
			}
		}
	);
	const { data: generalUsage, refetch } = useGeneralJobUsage({
		enabled: isSuccess
	});
	const { getJobCapacity } = useGetJobKind(generalUsage);
	const jobCapacity = getJobCapacity(JobKind.Job);
	const internshipCapacity = getJobCapacity(JobKind.Internship);

	return (
		<Card
			style={{
				flex: 1,
				margin: 0
			}}>
			<div className="d-flex justify-content-start align-items-start flex-column gap-4 border-bottom border-1 pb-3 border-gray-7">
				<span className={styles.title}>{t('label.job-partners')}</span>
				<div className={styles['find-partner']}>
					<span>
						{t(
							'label.support-your-local-community-partner-buying-subscriptions-visible-your-local-community'
						)}
						.
					</span>
					<div>
						<Button
							color={Colors['white-primary']}
							onClick={() => {
								setFindPartner(true);
							}}>
							<div className="d-flex gap-2">
								<FontAwesome icon="search" size="lg" />
								{t('button.find-community-partners')}
							</div>
						</Button>
					</div>
				</div>
				{JobKind.Job == fields?.kind && (
					<div className="d-flex gap-2">
						<span>{t('label.total-jobs-usage')}:</span>
						{!jobCapacity ? (
							'---'
						) : (
							<span>
								{(jobCapacity?.usage || 0) +
									' / ' +
									(jobCapacity?.capacity == null
										? t('label.unlimited')
										: jobCapacity?.capacity)}
							</span>
						)}
					</div>
				)}
				{JobKind.Internship == fields?.kind && (
					<div className="d-flex gap-2">
						<span>{t('label.total-internships-usage')}:</span>
						{!internshipCapacity ? (
							'---'
						) : (
							<span>
								{(internshipCapacity?.usage || 0) +
									' / ' +
									(jobCapacity?.capacity == null
										? t('label.unlimited')
										: jobCapacity?.capacity)}
							</span>
						)}
					</div>
				)}
			</div>
			<div className={styles['subscription-card-wrapper']}>
				{subscriptions?.items.length === 0 && (
					<NoData message="title.no-subscription" />
				)}
				{subscriptions?.items
					.filter((item) => {
						return (
							item.status == SubscriptionStatus.active ||
							item.status == SubscriptionStatus.expired
						);
					})
					.map((subscription) => {
						return (
							<div
								key={subscription.id}
								className={styles['subscription-card']}>
								<div className="flex-10.5">
									<img
										src={
											subscription?.communityPartnerInfo
												.logo || defaultImage
										}
										alt="Logo"
										className={styles['subscription-logo']}
									/>
								</div>

								<div className="flex-1 d-flex  gap-1 flex-column">
									<span className="fw-semibold">
										{
											subscription.communityPartnerInfo
												.organizationName
										}
									</span>
									<span className="fw-light">
										{subscription.description}
									</span>
								</div>
							</div>
						);
					})}
			</div>

			{isFetching && <LoadingContent title="loading..." />}

			<FindPartners
				isPremium={false}
				isOpen={findPartner}
				toggleDrawer={() => {
					setFindPartner((prev) => !prev);
				}}
			/>
		</Card>
	);
}

export default JobPartners;
