import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
	BusinessPartnerMe,
	BusinessPartnerProfile,
	Colors,
	CommunityPartnerMe,
	CommunityPartnerProfile,
	SocialNetworks
} from 'Types';
import { calculateBrightness, clx, formatLocaleDateTime, config } from 'Utils';
import {
	Button,
	ColorPickerPopover,
	FontAwesome,
	ModalBoxPublish
} from 'Elements';
import {
	useOutsideClick,
	useResponsive,
	useScrollContext,
	useTheme,
	useAxios,
	useModal,
	updateBusinessPartnerEditProfile,
	updateCommunityPartnerEditProfile
} from 'Hooks';
import styles from 'partials/homepage/home-header.module.scss';

interface Props {
	previewUrl: string;
	partnerProfileId: string;
	isFetchingProfile: boolean;
	isDirtyForm: boolean;
	data: BusinessPartnerProfile | CommunityPartnerProfile;
	dataPartnerMe: BusinessPartnerMe | CommunityPartnerMe;
	type: 'businessPartner' | 'communityPartner';
	onChangeValue: (
		objKey: string,
		key: string,
		value: string | boolean
	) => void;
	refetch: () => void;
	refetchPartnerMe?: () => void;
	validForm: any;
}

export const HomepageHeader: FC<Props> = ({
	previewUrl,
	type,
	data,
	partnerProfileId,
	dataPartnerMe,
	isDirtyForm,
	validForm,
	onChangeValue,
	refetch,
	refetchPartnerMe
}) => {
	const { theme } = useTheme();
	const { t } = useTranslation();
	const { colorType } = useScrollContext();
	const { isMobileAndBelow } = useResponsive();
	const popoverRef = useRef<HTMLDivElement>(null);
	const { isLoading: submittingColor } = useAxios();
	const [openColorPicker, toggleColorPicker] = useState(false);
	const [color, setColor] = useState(theme[type]?.primaryColor);
	const [loading, setLoading] = useState(isDirtyForm);
	const [openModalPublish, toggleModalPublish] = useModal();
	const [openModalUnPublish, toggleModalUnPublish] = useModal();

	const getBrightness = useMemo(() => {
		return calculateBrightness(color);
	}, [color]);

	const togglePicker = () => {
		toggleColorPicker(!openColorPicker);
	};

	const onSubmitColor = async (color: string) => {
		onChangeValue('header', 'themeColor', color);
		// setSubmittingColor(true);
		setColor(color);
		togglePicker();
	};

	useOutsideClick(popoverRef, togglePicker);

	const onPreviewPage = () => {
		window.open(previewUrl, '_blank', 'noopener,noreferrer');
	};

	const onPublicPage = () => {
		window.open(
			`${config.publicUrl}/${
				type === 'businessPartner'
					? 'business-partner'
					: 'community-partner'
			}/${dataPartnerMe?.id}/${dataPartnerMe?.slugUrl}`,
			'_blank',
			'noopener,noreferrer'
		);
	};

	useEffect(() => {
		// Set selected color on get new data
		if (data?.header?.themeColor) {
			setColor(data?.header?.themeColor);
		}
	}, [data?.header]);
	useEffect(() => {
		setLoading(!isDirtyForm);
	}, [isDirtyForm]);

	const onClickSave = async () => {
		setLoading(true);
		if (type === 'businessPartner') {
			await updateBusinessPartnerEditProfile(
				partnerProfileId as string,
				{
					...data,
					socialNetworks: data?.socialNetworks || [],
					benefitItems: data?.benefitList
				} as any,
				undefined
			);
			refetch();
		} else {
			await updateCommunityPartnerEditProfile(
				partnerProfileId as string,
				{
					...data,
					socialNetworks: data?.socialNetworks || []
				} as any,
				undefined
			);
			refetch();
		}
	};

	return (
		<header className={styles.header}>
			<Container
				fluid
				className={clx(styles.container, 'container-lg px-3')}>
				<nav className={clx(styles.navbar, 'd-flex flex-column')}>
					<div className="d-flex flex-wrap align-items-center justify-content-md-between mb-2">
						<div className="d-flex">
							<h6 className="fs-6 fw-400 mb-3 mb-md-0 me-3">
								<Link
									to="/"
									className="d-flex align-items-center back-button">
									<FontAwesome
										icon="arrow-left"
										size={isMobileAndBelow ? 'lg' : '1x'}
										className="me-3 back-icon"
									/>
									{!isMobileAndBelow &&
										t('title.back-to-profile')}
								</Link>
							</h6>
						</div>
						<div className="d-flex flex-wrap gap-3">
							{type === 'businessPartner' && (
								<>
									{dataPartnerMe?.publishInfo?.status ===
										1 && (
										<Button
											size="sm"
											color={Colors['white-red']}
											onClick={toggleModalUnPublish}
											className={clx(
												styles.actionButton,
												'gap-2'
											)}>
											<FontAwesome
												icon={'triangle-exclamation'}
												size="1x"
												className="me-2"
											/>
											{t('button.unPublish')}
										</Button>
									)}
									{data?.version !==
										dataPartnerMe?.publishInfo?.version && (
										<Button
											size="sm"
											color={Colors['white-primary']}
											onClick={toggleModalPublish}
											className={clx(
												styles.actionButton,
												'gap-2'
											)}>
											<FontAwesome
												icon={'code-branch'}
												size="1x"
												className="me-2"
											/>
											{t('button.publish')}
										</Button>
									)}
								</>
							)}

							{type === 'communityPartner' &&
								dataPartnerMe?.status === 2 && (
									<>
										{dataPartnerMe?.publishInfo?.status ===
											1 && (
											<Button
												size="sm"
												color={Colors['white-red']}
												onClick={toggleModalUnPublish}
												className={clx(
													styles.actionButton,
													'gap-2'
												)}>
												<FontAwesome
													icon={
														'triangle-exclamation'
													}
													size="1x"
													className="me-2"
												/>
												{t('button.unPublish')}
											</Button>
										)}
										{data?.version !==
											dataPartnerMe?.publishInfo
												?.version && (
											<Button
												size="sm"
												color={Colors['white-primary']}
												onClick={toggleModalPublish}
												className={clx(
													styles.actionButton,
													'gap-2'
												)}>
												<FontAwesome
													icon={'code-branch'}
													size="1x"
													className="me-2"
												/>
												{t('button.publish')}
											</Button>
										)}
									</>
								)}
							<Button
								disabled={!previewUrl}
								color={Colors.secondary}
								size={isMobileAndBelow ? 'sm' : 'md'}
								className={clx(styles.actionButton, 'gap-2')}
								onClick={onPreviewPage}>
								<FontAwesome icon="eye" size="lg" />
								{t('button.preview')}
							</Button>
							{dataPartnerMe?.publishInfo?.version && (
								<Button
									onClick={onPublicPage}
									className="flex-fill">
									<FontAwesome
										icon="eye"
										className="me-2"
										size="lg"
									/>
									{t('button.view-public-page')}
								</Button>
							)}
							<Button
								size={isMobileAndBelow ? 'sm' : 'md'}
								color={
									colorType === 'colorize'
										? Colors['border-primary']
										: Colors['border-gray-3']
								}
								className={clx(
									styles.actionButton,
									styles.colorPicker,
									'gap-2 d-none d-md-flex'
								)}
								onClick={togglePicker}>
								<span
									style={{
										backgroundColor:
											color || theme[type].primaryColor
									}}
									className={styles.theme_color}>
									<FontAwesome
										icon="eye-dropper"
										size="1x"
										className={
											getBrightness <= 155
												? 'text-gray-1'
												: 'text-gray-4'
										}
									/>
								</span>
								{t('button.theme-color')}
							</Button>
							<Button
								disabled={
									loading ||
									!validForm['contactPerson-fullName']
								}
								className={clx(styles.actionButton, 'gap-2')}
								onClick={onClickSave}>
								{t('button.save')}
							</Button>
							{openColorPicker && (
								<ColorPickerPopover
									ref={popoverRef}
									color={color}
									onChange={setColor}
									onSubmit={onSubmitColor}
									isSubmitting={submittingColor}
									onClose={togglePicker}
								/>
							)}
						</div>
					</div>
					<div className="d-flex align-items-center justify-content-center gap-2">
						<div className="position-relative d-flex flex-wrap gap-2">
							{data?.auditInfo && (
								<div className="d-flex flex-wrap gap-3 align-items-center ">
									<div className="d-flex gap-2 ">
										<FontAwesome
											icon="code-branch"
											color={Colors.primary}
											size="sm"
										/>
										<span className="fw-400 fs-sm">
											{t('text.current-profile-version')}:
										</span>
										<span>{data?.version}</span>
									</div>
									{dataPartnerMe?.publishInfo?.version && (
										<div className="d-flex gap-2 flex-wrap">
											<FontAwesome
												icon="code-branch"
												color={Colors.primary}
												size="sm"
											/>
											<span className="fw-400 fs-sm">
												{' '}
												{t('text.published-version')}:
											</span>
											<span>
												{
													dataPartnerMe?.publishInfo
														?.version
												}
											</span>
											<span>
												{dataPartnerMe?.publishInfo
													?.lastUpdatedAt
													? `(${t('text.at')} 
											${formatLocaleDateTime(dataPartnerMe?.publishInfo?.lastUpdatedAt)})`
													: ''}
											</span>
										</div>
									)}
									<div className="d-flex gap-2 flex-wrap">
										<FontAwesome
											icon="calendar"
											color={Colors.primary}
											size="sm"
										/>
										<span className="fw-400 fs-sm">
											{' '}
											{t('text.last-updated-at')}:
										</span>
										<span>
											{data?.auditInfo?.updatedAt
												? formatLocaleDateTime(
														data?.auditInfo
															?.updatedAt
												  )
												: ''}
										</span>
									</div>
								</div>
							)}
							{/* {type === 'communityPartner' && (
							<DecorationDropDown>
								<DropdownItem
									className="d-flex align-items-center gap-2 text-red"
									onClick={toggleModalDelete}>
									<FontAwesome
										icon="triangle-exclamation"
										size="lg"
									/>
									{t('dropdown.delete-account')}
								</DropdownItem>
							</DecorationDropDown>
						)} */}
						</div>
					</div>
				</nav>
			</Container>
			<ModalBoxPublish
				userType={type}
				profileId={partnerProfileId}
				status={dataPartnerMe?.status as number}
				refetch={refetch}
				refetchPartnerMe={refetchPartnerMe}
				type={'publish'}
				isOpen={openModalPublish}
				onClose={toggleModalPublish}
			/>
			<ModalBoxPublish
				userType={type}
				profileId={partnerProfileId}
				status={dataPartnerMe?.status as number}
				refetch={refetch}
				refetchPartnerMe={refetchPartnerMe}
				type={'unPublish'}
				isOpen={openModalUnPublish}
				onClose={toggleModalUnPublish}
			/>
		</header>
	);
};
