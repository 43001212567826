import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import type { SubmitHandler } from 'react-hook-form/dist/types';
import { clx } from 'src/helpers/Utils';
import { useModal, usePrompt } from 'Hooks';
import { Colors, JobForm, JobFormDescription, JobGenerateDescriptionByAIResult } from 'Types';
import { FontAwesome, RichTextController, StepNavigation, SwitchController, Button, ModalBoxGenerateJobDescriptionByAI, Badge } from 'Elements';
import styles from 'partials/step/step-container.module.scss';

interface Props {
	fields: JobForm;
	onPrev: () => void;
	onNext: (data: JobFormDescription) => void;
}

export const CreateJobDescription: FC<Props> = ({ fields, onPrev, onNext }) => {
	const { t } = useTranslation();
	const [openModalAI, toggleModalAI] = useModal();
	const [valuesGenerateByAI, setValuesGenerateByAI] = useState<{ description: string, requirements: string, responsibilities: string }>();

	const {
		watch,
		control,
		handleSubmit,
		setValue,
		formState: { isDirty }
	} = useForm<JobFormDescription>({
		defaultValues: fields.step4
	});

	usePrompt(t('forms.leave-screen-massage'), isDirty);

	const onSubmit: SubmitHandler<JobFormDescription> = (data) => {
		onNext?.(data);
	};

	const fillDescriptionByAI = (data: JobGenerateDescriptionByAIResult) => {
		setValue('description', data.description);
		setValue('requirementsText', data.requirements);
		setValue('responsibilitiesText', data.responsibilities);
		setValuesGenerateByAI({
			description: data?.description,
			requirements: data?.requirements,
			responsibilities: data?.responsibilities
		});
	}

	return (
		<>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className={styles.form}
				noValidate>
				<Row>
					<Col xxl={8}>
						<FormGroup className="mb-4">
							<div className="d-flex align-items-center mb-2">
								<Label htmlFor="description" className="mb-0">
									{t('forms.job.desc')}
								</Label>
								<SwitchController
									control={control}
									name="showDescription"
									boxClassName="ms-auto"
									side="right"
									label={t('label.job.show-in-job-page')}
								/>
							</div>
							<RichTextController
								control={control}
								name="description"
								readOnly={!watch('showDescription')}
							/>
							{watch('description') == `<p>${valuesGenerateByAI?.description.replace(/(\r\n|\n|\r)/gm, " ")}</p>` &&
								< div className='text-end py-1'>
									<Badge
										color={Colors['tertiary-dark']}>
										<FontAwesome
											icon="wand-magic-sparkles"
											color="text-tertiary-dark"
											className='me-1'
										/>
										{t('badge.generated-with-AI')}
									</Badge>
								</div>
							}
						</FormGroup>
						<FormGroup className="mb-4">
							<div className="d-flex align-items-center mb-2">
								<Label htmlFor="requirementsText" className="mb-0">
									{t('forms.job.requirements')}
								</Label>
								<SwitchController
									control={control}
									name="showRequirementsText"
									boxClassName="ms-auto"
									side="right"
									label={t('label.job.show-in-job-page')}
								/>
							</div>
							<RichTextController
								control={control}
								name="requirementsText"
								readOnly={!watch('showRequirementsText')}
							/>
							{watch('requirementsText') == `<p>${valuesGenerateByAI?.requirements.replace(/(\r\n|\n|\r)/gm, " ")}</p>` &&
								<div className='text-end py-1'>
									<Badge
										color={Colors['tertiary-dark']}>
										<FontAwesome
											icon="wand-magic-sparkles"
											color="text-tertiary-dark"
											className='me-1'
										/>
										{t('badge.generated-with-AI')}
									</Badge>
								</div>
							}
						</FormGroup>
						<FormGroup className="mb-4">
							<div className="d-flex align-items-center mb-2">
								<Label
									htmlFor="responsibilitiesText"
									className="mb-0">
									{t('forms.job.responsibilities')}
								</Label>
								<SwitchController
									control={control}
									name="showResponsibilitiesText"
									boxClassName="ms-auto"
									side="right"
									label={t('label.job.show-in-job-page')}
								/>
							</div>
							<RichTextController
								control={control}
								name="responsibilitiesText"
								readOnly={!watch('showResponsibilitiesText')}
							/>
							{watch('responsibilitiesText') == `<p>${valuesGenerateByAI?.responsibilities.replace(/(\r\n|\n|\r)/gm, " ")}</p>` &&
								<div className='text-end py-1'>
									<Badge
										color={Colors['tertiary-dark']}>
										<FontAwesome
											icon="wand-magic-sparkles"
											color="text-tertiary-dark"
											className='me-1'
										/>
										{t('badge.generated-with-AI')}
									</Badge>
								</div>
							}
						</FormGroup>
						<FormGroup className="mb-4">
							<div className="d-flex align-items-center mb-2">
								<Label htmlFor="benefitsText" className="mb-0">
									{t('forms.job.benefits')}
								</Label>
								<SwitchController
									control={control}
									name="showBenefitsText"
									boxClassName="ms-auto"
									side="right"
									label={t('label.job.show-in-job-page')}
								/>
							</div>
							<RichTextController
								control={control}
								name="benefitsText"
								readOnly={!watch('showBenefitsText')}
							/>
						</FormGroup>
						<StepNavigation onPrev={onPrev} />
					</Col>
					<Col xxl={4} className='order-first order-xxl-2 pb-3'>
						<div className={clx('bg-gray-1 p-3', styles.roundedMd)}>
							<div className='d-flex'>
								<div>
									<FontAwesome
										icon="wand-magic-sparkles"
										size="lg"
										color="text-primary"
										className='me-1'
									/>
								</div>
								<div>
									<h1 className='fs-6 text-primary'>{t('title.job.generate-with-AI')}</h1>
									<p className='text-gray-3'>{t('text.create-job-description-with-AI')}</p>
								</div>
							</div>
							<Button
								type="button"
								color={Colors['white-primary']}
								className={clx('w-100')}
								onClick={toggleModalAI}>
								<>
									<FontAwesome
										icon="wand-magic-sparkles"
										size="lg"
										color="text-primary"
										className='me-1'
									/>
									<span className='fw-semibold'>{t('button.job.generate')}</span>
								</>
							</Button>
						</div>
					</Col>
				</Row>
			</form >
			<ModalBoxGenerateJobDescriptionByAI
				jobTitle={fields.step1.title}
				jobTags={fields.step3.jobTags?.map(({ title }) => title).join(',') || ''}
				isOpen={openModalAI}
				onClose={toggleModalAI}
				fillDescriptionByAI={fillDescriptionByAI}
			/>
		</>
	);
};
