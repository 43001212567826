import './CommunityPartnerPreviewCompanyOverviewPremiumDrawer.css';
import { clx, currencyFormatter } from 'src/helpers/Utils';
import styles from 'partials/homepage/section/community-partner-business-partner-overview-section.module.scss';
import { useSubscriptionPlanDetails } from 'src/hooks/useSubscriptionPlans';
import { Box } from 'Styles';
import {
	Button,
	ButtonProps,
	Carousel,
	FontAwesome,
	ShimmerImage
} from 'src/containers/Elements';
import { useTheme } from 'Hooks';
import { Colors } from 'src/types/Color';
import styled from 'styled-components';
import { darken } from 'polished';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Thumbs } from 'swiper/modules';
import { useEffect, useRef, useState } from 'react';

const PartnerButton = styled((props: ButtonProps) => (
	<Button color={Colors.empty} {...props} />
))`
	${({ theme }) =>
		theme &&
		`
			background-color: #fff !important;
			color: #000 !important;
			&:hover {
				color: #000;
				background-color: ${darken(0.07, '#fff')} !important;
			}
		`}
`;

const CommunityPartnerPreviewCompanyOverviewPremiumDrawer = (props) => {
	const { t } = useTranslation();
	const { theme } = useTheme();
	const [thumbsSwiper, setThumbsSwiper] = useState<any>();
	const [open, setOpen] = useState<boolean>(false);
	const [image, setImage] = useState<any>();
	const targetRef = useRef<HTMLDivElement | null>(null);
	const { data: subscriptionPlan, isLoading } = useSubscriptionPlanDetails(
		props?.selected?.id as string
	);

	const breakpoints = {
		0: {
			slidesPerView: 1
		},
		768: {
			slidesPerView: 2
		},
		992: {
			slidesPerView: 3
		}
	};

	useEffect(() => {
		if (targetRef.current) {
			const elementPosition =
				targetRef.current.getBoundingClientRect().top + window.scrollY;
			const offset = 100;
			window.scrollTo({
				top: elementPosition - offset,
				behavior: 'smooth'
			});
		}
	}, []);

	const number =
		window.innerWidth >= 992 ? 3 : window.innerWidth >= 768 ? 2 : 1;

	return (
		<>
			<div ref={targetRef} className="drawer">
				<div className="buttonClose">
					<Button
						color={Colors['warning']}
						className="gap-2"
						onClick={() => {
							props?.setIsOpen(false);
						}}>
						<FontAwesome icon="close" size="lg" />
						{t('button.close')}
					</Button>
				</div>
				<div className="drawer-content">
					{!isLoading && (
						<div className="d-flex flex-column flex-md-row align-items-start">
							<div className="d-flex align-items-start flex-column flex-md-row gap-150 mb-4">
								<div
									className="d-flex flex-column "
									style={{ gap: '30px' }}>
									<div
										key={props?.selected?.id}
										className={clx(
											styles.boxPlanDrawer,
											'h-auto'
										)}>
										<div
											key={props?.selected?.id}
											className="d-flex flex-column gap-1">
											<div className="fs-4 fw-700">
												{props?.selected?.name}
											</div>
											<div className="fs-5 fw-700">
												{currencyFormatter(
													props?.selected?.price ?? 0
												)}
											</div>
											<div className="fs-sm fw-400">
												{
													subscriptionPlan?.shortDescription
												}
											</div>
										</div>
									</div>
									<div className="d-flex flex-column flex-md-row align-items-center gap-3">
										{!isLoading &&
											subscriptionPlan?.contactPerson
												?.avatarUrl && (
												<div
													className="d-flex flex-column align-items-center justify-content-center gap-4"
													style={{
														margin: '0 auto'
													}}>
													<Box
														$hasShadow={true}
														$bgColor={
															theme[
																'communityPartner'
															].primaryColor
														}
														className={
															styles.imageWrapper
														}>
														<ShimmerImage
															src={
																subscriptionPlan
																	?.contactPerson
																	?.avatarUrl
															}
															defaultImage={''}
															alt={
																subscriptionPlan
																	?.contactPerson
																	?.fullName
															}
															width={400}
															height={400}
															className={
																styles.image
															}
														/>
													</Box>
													<div>
														<h6 className="h3 text-gray-1 text-center lh-sm">
															{
																subscriptionPlan
																	?.contactPerson
																	?.fullName
															}
														</h6>
													</div>
												</div>
											)}
										<div>
											<span className="text-white fs-base fw-400">
												{
													subscriptionPlan
														?.contactPerson
														?.description
												}
											</span>
											<div className="d-flex align-items-center mt-4 pb-4">
												<PartnerButton
													className="rounded-pill px-md-4 fs-base"
													label={
														t(
															'button.become-partner'
														) as string
													}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
							{subscriptionPlan?.subscriptionPlanGalleries && (
								<div className="w-90 mb-5 w-100 w-md-50 px-4 mx-auto">
									{subscriptionPlan?.subscriptionPlanGalleries
										?.length > 0 && (
										<>
											<Carousel
												centeredSlidesBounds
												className="mt-3 w-100 h-100 w-md-50 h-md-50"
												navigationGroup={
													subscriptionPlan
														?.subscriptionPlanGalleries
														?.length > number
														? true
														: false
												}
												slidesPerView={1}
												thumbs={{
													swiper:
														thumbsSwiper &&
														!thumbsSwiper.destroyed
															? thumbsSwiper
															: null
												}}
												// className="my-28"
												modules={[Pagination, Thumbs]}
												darkBackground>
												{subscriptionPlan?.subscriptionPlanGalleries?.map(
													(item: any, index: any) => {
														return (
															<SwiperSlide
																key={
																	item.id +
																	index
																}
																style={{
																	width: '150px',
																	cursor: 'pointer'
																}}>
																{item.mediaType ===
																0 ? (
																	<img
																		alt={
																			item.id
																		}
																		style={{
																			borderRadius:
																				'8px',
																			objectFit:
																				'cover'
																		}}
																		className="w-100 h-100"
																		src={
																			item.url
																		}
																		onClick={() => {
																			setImage(
																				item
																			);
																			setOpen(
																				true
																			);
																		}}
																	/>
																) : (
																	<div
																		style={{
																			borderRadius:
																				'8px',
																			background:
																				'#f5f5f7',
																			position:
																				'relative',
																			width: '100%',
																			height: '100%'
																		}}
																		onClick={() => {
																			setImage(
																				item
																			);
																			setOpen(
																				true
																			);
																		}}>
																		<video
																			autoPlay
																			controls
																			className="w-100 h-100"
																			src={
																				item.url
																			}
																		/>
																		<div
																			style={{
																				position:
																					'absolute',
																				top: 0,
																				left: 0,
																				width: '100%',
																				height: '100%',
																				textAlign:
																					'center',
																				display:
																					'flex',
																				alignItems:
																					'center',
																				justifyContent:
																					'center'
																			}}>
																			<FontAwesome
																				icon="play"
																				size="3x"
																				className="text-white"
																			/>
																		</div>
																	</div>
																)}
															</SwiperSlide>
														);
													}
												)}
											</Carousel>
											<Swiper
												onSwiper={setThumbsSwiper}
												spaceBetween={10}
												navigation
												className="mt-3 w-100 h-100 w-md-50 h-md-50"
												slidesPerView={4}
												modules={[Thumbs, Navigation]}>
												{subscriptionPlan?.subscriptionPlanGalleries?.map(
													(item) => (
														<SwiperSlide
															key={item.id}
															style={{
																width: '150px',
																cursor: 'pointer'
															}}>
															{item.mediaType ===
															0 ? (
																<img
																	alt={
																		item.id
																	}
																	style={{
																		borderRadius:
																			'8px',
																		objectFit:
																			'cover'
																	}}
																	className="w-100 h-100"
																	src={
																		item.url
																	}
																/>
															) : (
																<div
																	style={{
																		borderRadius:
																			'8px',
																		background:
																			'#f5f5f7',
																		position:
																			'relative',
																		width: '100%',
																		height: '100%'
																	}}>
																	<video
																		autoPlay
																		controls
																		className="w-100 h-100"
																		src={
																			item.url
																		}
																	/>
																	<div
																		style={{
																			position:
																				'absolute',
																			top: 0,
																			left: 0,
																			width: '100%',
																			height: '100%',
																			textAlign:
																				'center',
																			display:
																				'flex',
																			alignItems:
																				'center',
																			justifyContent:
																				'center'
																		}}>
																		<FontAwesome
																			icon="play"
																			size="3x"
																			className="text-white"
																		/>
																	</div>
																</div>
															)}
														</SwiperSlide>
													)
												)}
											</Swiper>
										</>
									)}
								</div>
							)}
						</div>
					)}
					<div>
						<Carousel
							centeredSlidesBounds
							navigation
							modules={[Navigation]}
							spaceBetween={16}
							className="my-md-5"
							breakpoints={breakpoints}>
							{subscriptionPlan?.features?.map((item, index) => {
								return (
									<SwiperSlide
										key={index}
										className={clx('h-auto')}>
										<div
											className="d-flex gap-3 align-items-start px-3"
											style={{
												borderRight: '1px solid #424249'
											}}>
											<FontAwesome
												size="3x"
												icon={item.icon}
												className="mt-1"
											/>
											<div className="d-flex flex-column gap-2 text-white">
												<span className="fs-5 fw-400">
													{item.title}
												</span>
												<span className="fs-small fw-400 text-gary-2">
													{item.description}
												</span>
											</div>
										</div>
									</SwiperSlide>
								);
							})}
						</Carousel>
					</div>
				</div>
			</div>
		</>
	);
};

export default CommunityPartnerPreviewCompanyOverviewPremiumDrawer;
