import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { EventDetails } from 'Types';
import { formatLocalAddress } from 'Utils';
import styles from 'partials/event/event-organizer-section.module.scss';

interface Props {
	event: EventDetails;
}

export const EventOrganizerSection: FC<Props> = memo(({ event }) => {
	const { t } = useTranslation();
	return (
		<div className={styles.box}>
			<h4 className="h6">{t('title.event.about-organizer')}</h4>
			<p className="lh-base">
				<span className="fw-500">{event?.communityPartnerName}</span>
				<br />
				{formatLocalAddress(
					event?.communityPartnerAddress?.street,
					event?.communityPartnerAddress?.houseNo,
					event?.communityPartnerAddress?.postalCode,
					event?.communityPartnerAddress?.city,
					event?.communityPartnerAddress?.state
				)}
			</p>
			<h5 className="fs-small">{t('title.notice')}:</h5>
			<p className="lh-md fs-small mb-0 text-gray-3">
				{t('text.event.information-about-organizer')}
			</p>
		</div>
	);
});
EventOrganizerSection.displayName = 'EventOrganizerSection';
