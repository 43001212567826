import styled from 'styled-components';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useBusinessPartnerInvoiceDetails, useSubscription, useSubscriptionPayments } from 'Hooks';
import { Colors, Invoice, SubscriptionDetails, SubscriptionPayment } from 'Types';
import {
	Button,
	Card,
	CompanyTransactionInvoice,
	CompanyTransactionInvoicePayment,
	DetailsSubscription,
	LoadingContent
} from 'Elements';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const TransactionCard = styled(Card)`
	height: 100%;
	min-height: 250px;
`;

// interface Props {
// 	transaction?: SubscriptionPayment;
// }

export const ViewInvoice: FC = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { invoice_id } = useParams();

	const { data: transaction } = useBusinessPartnerInvoiceDetails(invoice_id ?? '', {
		enabled: !!invoice_id
	});

	const { data: subscription, isLoading } = useSubscription(
		transaction?.subscriptionId as string,
		{
			enabled: !!transaction?.subscriptionId
		}
	);

	return (
		<>
			<Row className="mb-4">
				<Col md={6}>
					<TransactionCard>
						{isLoading && <LoadingContent />}
						{!isLoading && (
							<CompanyTransactionInvoice
								transaction={transaction as Invoice}
							/>
						)}
					</TransactionCard>
				</Col>
				<Col md={6} className="mt-4 mt-md-0">
					<TransactionCard>
						{isLoading && <LoadingContent />}
						{!isLoading && (
							<CompanyTransactionInvoicePayment
								transaction={transaction as Invoice}
								baseUrl="invoices"
							/>
						)}
					</TransactionCard>
				</Col>
			</Row>
			<Card>
				<h6 className="text-gray-3 mb-3 fs-6 fw-400">
					{t('title.product')}
				</h6>
				{subscription && (
					<DetailsSubscription
						showPrice={false}
						subscription={subscription as SubscriptionDetails}
						subscriptionId={subscription?.id as string}
						communityPartnerInfo={
							subscription?.communityPartnerInfo
						}
						isLoading={isLoading}
						canAutoRenew={false}
					/>
				)}
			</Card>
			{/* <div className="text-end mt-3">
				<Button
					type="button"
					color={Colors['white-primary']}
					className="me-auto"
					onClick={() => navigate('/invoices')}>
					{t('button.back')}
				</Button>
			</div> */}
		</>
	);
};
