import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Badge,
	Button,
	FontAwesome,
	LoadingSpinner,
	ModalBoxPlanInf
} from 'Elements';
import { currencyFormatter } from 'Utils';
import { Colors, SubscriptionPlan } from 'Types';
import styles from 'partials/card/subscription-plan-card.module.scss';
import { useSubscriptionCalculate } from 'src/hooks/useSubscription';
import { useModal } from 'src/hooks/useModal';

interface Props {
	card: SubscriptionPlan;
	selectedCommunityPartnerId?: string;
	selected: boolean;
	subscriptionPlanPrice?: number;
	loadingPlansFeature: boolean;
	onSelect: (plan: SubscriptionPlan) => void;
}

export const SubscriptionPlanCard: FC<Props> = ({
	card,
	loadingPlansFeature,
	selectedCommunityPartnerId,
	subscriptionPlanPrice,
	onSelect
}) => {
	const { t } = useTranslation();
	const [openModalPlanInfo, toggleModalPlanInfo] = useModal();
	const handleSelectPlan = () => {
		onSelect(card);
	};

	const { data: subscriptionCalculate, isLoading } = useSubscriptionCalculate(
		selectedCommunityPartnerId as string,
		card.id as string,
		'',
		card.type as number,
		{
			enabled: !!selectedCommunityPartnerId
		}
	);

	return (
		<>
			<div className={styles.cardPlan}>
				<div className="d-flex align-items-center justify-content-between">
					<h1 className="h4 fs-5 text-gray-4">{card.name}</h1>
					{card?.isRecommended && (
						<Badge color={Colors.primary}>
							{t('forms.recommended')}
						</Badge>
					)}
				</div>
				<h4
					className={`fw-500 fs-5 ${subscriptionPlanPrice && 'mb-0'}`}
					style={{
						textDecoration: subscriptionPlanPrice
							? 'line-through'
							: 'none',
						color: subscriptionPlanPrice ? '#AAAAB2' : '#5d5c70'
					}}>
					{currencyFormatter(card.price)}
				</h4>
				{subscriptionPlanPrice && (
					<h4 className="fw-500 fs-5">
						{currencyFormatter(card.price - subscriptionPlanPrice)}
					</h4>
				)}
				{!subscriptionPlanPrice && (
					<span className="text-gray-3 fw-500">
						{`${t('title.per')} ${card.durationDays} ${t(
							'title.days'
						)}`}
					</span>
				)}
				<div
					className="pt-2 lh-base my-3"
					dangerouslySetInnerHTML={{
						__html: card.shortDescription
					}}
				/>
				{/* <div
					className="lh-base mb-4"
					dangerouslySetInnerHTML={{
						__html: card.description
					}}
				/> */}
				{loadingPlansFeature && <LoadingSpinner />}
				{!loadingPlansFeature && card?.featureData && (
					<div className="px-2 pt-3 pb-2">
						<div className="d-flex align-items-center gap-2 mb-2">
							<FontAwesome
								icon="briefcase"
								size="lg"
								color="text-primary"
							/>
							<span className="fw-500 fs-base">
								{t('menu.job')}
							</span>
						</div>
						{card?.featureData?.jobSetting?.jobEnabled && (
							<div className="d-flex align-items-center gap-2 mb-2">
								<FontAwesome
									icon="check"
									size="1x"
									className="flex-shrink-0 text-secondary"
								/>
								<div className="fw-400 fs-sm">
									{t('text.feature.job-enabled')}
								</div>
							</div>
						)}
						{card?.featureData?.jobSetting?.publishJobEnabled && (
							<div className="d-flex align-items-center gap-2 mb-2">
								<FontAwesome
									icon="check"
									size="1x"
									className="flex-shrink-0 text-secondary"
								/>
								<div className="fw-400 fs-sm">
									{t('text.feature.publish-job-enabled')}
									{` (${t('label.max')}: ${
										card?.featureData?.jobSetting
											?.maxPublishJobs
											? card?.featureData?.jobSetting
													?.maxPublishJobs
											: t('label.unlimited')
									})`}
								</div>
							</div>
						)}
						{card?.featureData?.jobSetting?.jobsGoogleIndex && (
							<div className="d-flex align-items-center gap-2 mb-2">
								<FontAwesome
									icon="check"
									size="1x"
									className="flex-shrink-0 text-secondary"
								/>
								<div className="fw-400 fs-sm">
									{t('text.feature.jobs-google-index')}
								</div>
							</div>
						)}
						{card?.featureData?.jobSetting?.limitJobVisibility && (
							<div className="d-flex align-items-center gap-2 mb-2">
								<FontAwesome
									icon="check"
									size="1x"
									className="flex-shrink-0 text-secondary"
								/>
								<div className="fw-400 fs-sm">
									{t('text.feature.limit-job-visibility')}
								</div>
							</div>
						)}

						<div className="d-flex align-items-center gap-2 mb-2 pt-3">
							<FontAwesome
								icon="chalkboard-user"
								size="lg"
								color="text-primary"
							/>
							<span className="fw-500 fs-base">
								{t('text.internship')}
							</span>
						</div>
						{card?.featureData?.internshipSetting
							?.internshipEnabled && (
							<div className="d-flex align-items-center gap-2 mb-2">
								<FontAwesome
									icon="check"
									size="1x"
									className="flex-shrink-0 text-secondary"
								/>
								<div className="fw-400 fs-sm">
									{t('text.feature.internship-enabled')}
								</div>
							</div>
						)}
						{card?.featureData?.internshipSetting
							?.publishInternshipEnabled && (
							<div className="d-flex align-items-center gap-2 mb-2">
								<FontAwesome
									icon="check"
									size="1x"
									className="flex-shrink-0 text-secondary"
								/>
								<div className="fw-400 fs-sm">
									{t(
										'text.feature.publish-internship-enabled'
									)}
									{` (${t('label.max')}: ${
										card?.featureData?.internshipSetting
											?.maxPublishInternships
											? card?.featureData
													?.internshipSetting
													?.maxPublishInternships
											: t('label.unlimited')
									})`}
								</div>
							</div>
						)}
						{card?.featureData?.internshipSetting
							?.internshipManagerEnabled && (
							<div className="d-flex align-items-center gap-2 mb-2">
								<FontAwesome
									icon="check"
									size="1x"
									className="flex-shrink-0 text-secondary"
								/>
								<div className="fw-400 fs-sm">
									{t(
										'text.feature.internship-manager-enabled'
									)}
								</div>
							</div>
						)}

						<div className="d-flex align-items-center gap-2 mb-2 pt-3">
							<FontAwesome
								icon="memo-circle-info"
								size="lg"
								color="text-primary"
							/>
							<span className="fw-500 fs-base">
								{t('title.other')}
							</span>
						</div>
						{card?.featureData?.eventSetting?.eventsEnabled && (
							<div className="d-flex align-items-center gap-2 mb-2">
								<FontAwesome
									icon="check"
									size="1x"
									className="flex-shrink-0 text-secondary"
								/>
								<div className="fw-400 fs-sm">
									{t('text.feature.events-enabled')}
								</div>
							</div>
						)}
						{card?.featureData?.eventSetting
							?.publishEventsEnabled && (
							<div className="d-flex align-items-center gap-2 mb-2">
								<FontAwesome
									icon="check"
									size="1x"
									className="flex-shrink-0 text-secondary"
								/>
								<div className="fw-400 fs-sm">
									{t('text.feature.publish-events-enabled')}
									{` (${t('label.max')}: ${
										card?.featureData?.eventSetting
											?.maxPublishEvents
											? card?.featureData?.eventSetting
													?.maxPublishEvents
											: t('label.unlimited')
									})`}
								</div>
							</div>
						)}
						{card?.featureData?.userAccountSetting
							?.maxUserAccounts && (
							<div className="d-flex align-items-center gap-2 mb-2">
								<FontAwesome
									icon="check"
									size="1x"
									className="flex-shrink-0 text-secondary"
								/>
								<div className="fw-400 fs-sm">
									{t('text.feature.user-accounts')}
									{` (${t('label.max')}: ${
										card?.featureData?.userAccountSetting
											?.maxUserAccounts
											? card?.featureData
													?.userAccountSetting
													?.maxUserAccounts
											: t('label.unlimited')
									})`}
								</div>
							</div>
						)}
					</div>
				)}
			</div>
			<div>
				{card.price !== 0 && subscriptionCalculate?.totalAmount !== 0 && (
					<>
						{!isLoading && (
							<div className="mt-4 mb-3">
								<div className="d-flex flex-column">
									<div className="d-flex gap-2 pe-3 me-3 text-primary">
										<h5
											className="h5 mb-0"
											style={{
												textDecoration:
													subscriptionPlanPrice
														? 'line-through'
														: 'none',
												color: subscriptionPlanPrice
													? '#AAAAB2'
													: '#96bd68'
											}}>
											{currencyFormatter(
												subscriptionCalculate?.totalAmount ??
													0
											)}
										</h5>
										<span
											className="fw-300"
											style={{
												color: subscriptionPlanPrice
													? '#AAAAB2'
													: '#96bd68'
											}}>
											{t('title.excluded-tax')}
										</span>
									</div>
									{subscriptionPlanPrice &&
										subscriptionCalculate && (
											<div className="d-flex gap-2 pe-3 me-3 text-primary">
												<h5 className="h5 mb-0">
													{currencyFormatter(
														subscriptionPlanPrice
															? subscriptionCalculate?.totalAmount -
																	subscriptionPlanPrice
															: 0
													)}
												</h5>
												<span className="fw-300">
													{t('title.excluded-tax')}
												</span>
											</div>
										)}
									{!subscriptionPlanPrice && (
										<div className="text-gray-3 text-start d-flex gap-2 text-primary">
											<span className="fw-300 lh-base text-primary">
												{t('title.per')}
											</span>

											<span className="mt-auto fs-base text-primary">
												{`${
													subscriptionCalculate?.subscriptionDuration ??
													''
												} ${t('title.days')}`}
											</span>
										</div>
									)}
								</div>
							</div>
						)}
						{isLoading && (
							<div className="d-flex flex-column gap-2 mt-4 mb-2">
								<div
									className="bg-gray-2"
									style={{
										width: '150px',
										height: '17px'
									}}></div>
								<div
									className="bg-gray-2"
									style={{
										width: '100px',
										height: '15px'
									}}></div>
							</div>
						)}
					</>
				)}
				<Button
					color={Colors.primary}
					className="w-100"
					onClick={handleSelectPlan}>
					{t('button.select')}
				</Button>
				<div className="mt-2 mb-0">
					<Button
						color={Colors.empty}
						className="w-100"
						onClick={toggleModalPlanInfo}>
						{t('button.more-details')}
					</Button>
				</div>
			</div>
			{card && (
				<ModalBoxPlanInf
					continueButton={handleSelectPlan}
					isOpen={openModalPlanInfo}
					onClose={toggleModalPlanInfo}
					plan={card as SubscriptionPlan}
				/>
			)}
		</>
	);
};
