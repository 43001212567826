import { FC } from 'react';
import { Container } from 'reactstrap';
import { clx, config } from 'Utils';

import styles from 'partials/homepage/home-header-preview.module.scss';
import classes from 'partials/shared/header.module.scss';
import { Button, FontAwesome, IconLogo } from 'src/containers/Elements';
import { useTranslation } from 'react-i18next';
import { Colors } from 'src/types/Color';
import { UserCategory } from 'src/types/User';

interface Props {
	type: UserCategory;
	dataProfile: any;
}

export const HomepagePreviewHeader: FC<Props> = ({ dataProfile, type }) => {
	const { t } = useTranslation();

	const previewUrl = `${config.publicUrl}/${
		type === 'businessPartner' ? 'business-partner' : 'community-partner'
	}/${dataProfile?.id}/${dataProfile?.slugUrl}`;
	const onPreviewPage = () => {
		window.open(previewUrl, '_blank', 'noopener,noreferrer');
	};

	return (
		<header className={styles.header}>
			<Container>
				<nav className={clx(styles.navbar)}>
					<div>
						<IconLogo fill="#000" className={classes.logo_image} />
					</div>
					{dataProfile?.publishInfo?.version && (
						<div>
							<Button
								onClick={onPreviewPage}
								color={Colors['border-gray-6']}
								className="flex-fill gap-2">
								<FontAwesome icon="eye" size="lg" />
								{t('button.view-public-page')}
							</Button>
						</div>
					)}
					{/* <div
						className={clx(
							styles.menuBox,
							'd-flex align-items-center justify-content-center'
						)}>
						<span>{t('table.applicant')}</span>
						<span>{t('menu.agent')}</span>
						<span>{t('menu.business-partner')}</span>
						<span>{t('text.jobSearch')}</span>
						<span>{t('menu.internships')}</span>
						<span>{t('menu.event.key')}</span>
					</div> */}
				</nav>
			</Container>
		</header>
	);
};
