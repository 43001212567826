import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';

import {
	Button,
	Collapsible,
	FontAwesome,
	LoadingSpinner,
	ModalBoxSectionEditProfile,
	ProfileAddNewCard,
	ProfileJobCard,
	ReactSwitch,
	SectionHeadline
} from 'Elements';
import stylesProfile from 'partials/homepage/section/profile-hero-header.module.scss';
import { names } from 'Constants';
import { useModal } from 'src/hooks/useModal';
import { Colors } from 'Types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useProfileJobs } from 'src/hooks/useJob';

type Props = {
	isLoadingProfile: boolean;
	isFetchingProfile: boolean;
	onChangeValue: (
		objKey: string,
		key: string,
		value: string | boolean
	) => void;
	internships: {
		enabled: boolean;
		headline: string;
	};
};

export const BusinessPartnerInternshipSection = ({
	isLoadingProfile,
	onChangeValue,
	internships
}: Props) => {
	const { t } = useTranslation();
	const [openModal, toggleModal] = useModal();
	const { jobs } = names;
	const perRow = 3;
	const [next, setNext] = useState(perRow);
	const navigate = useNavigate();
	const addInternshipUrl = jobs.internships.add?.path as string;

	const { data, isLoading } = useProfileJobs({
		status: 1,
		pageSize: 20,
		pageNumber: 1,
		keyword: '',
		kind: 1
	});

	const handleMore = () => {
		setNext(next + perRow);
	};

	const handleNavigate = () => {
		navigate(addInternshipUrl, { state: { prevPath: '/homepage' } });
	};

	return (
		<section className="position-relative mb-5">
			<Container>
				{isLoadingProfile && isLoading && (
					<div className="d-flex align-items-center justify-content-center">
						<LoadingSpinner />
					</div>
				)}
				{!isLoadingProfile && internships && !isLoading && (
					<div className="position-relative">
						<Collapsible
							header={
								<div
									className={`d-flex flex-wrap gap-3 align-items-center justify-content-between mb-3 ${stylesProfile.switcherBox}`}>
									<div className="d-flex flex-wrap align-items-center gap-3">
										<h5 className="fs-5 fw-700 d-flex align-items-center">
											{' '}
											{t('menu.internships')}
										</h5>
										<ReactSwitch
											size="md"
											checked={internships?.enabled}
											onChange={(val: boolean) => {
												onChangeValue(
													'internships',
													'enabled',
													val
												);
											}}
										/>
										<span className="d-flex align-items-center mb-0 py-0 cursor-pointer">
											{t(
												'forms.show-agent-internships-in-home'
											)}
										</span>
									</div>
									<div>
										<Button
											size="sm"
											color={Colors['white-gray']}
											onClick={() => toggleModal()}>
											<FontAwesome
												icon="circle-info"
												size="1x"
												className="text-gray me-1"
											/>
											{t('button.more')}
										</Button>
									</div>
								</div>
							}>
							<SectionHeadline
								title={internships?.headline}
								titlePlaceholder={`[${t(
									'placeholder.internship-list-headline'
								)}]`}
								titleColor="text-gray-5"
								className="mx-auto text-center"
								onUpdateTitle={(val) =>
									onChangeValue(
										'internships',
										'headline',
										val
									)
								}
							/>
							{data && data?.items?.length > 0 && (
								<Row className="justify-content-center">
									{data.items?.slice(0, next)?.map((job) => {
										return (
											<Col
												md={6}
												lg={4}
												key={job.id}
												className="mb-4">
												<ProfileJobCard
													type="communityPartner"
													job={job}
												/>
											</Col>
										);
									})}
								</Row>
							)}
							<div className="d-flex align-items-center justify-content-center mt-3 gap-3">
								{data && data.items?.length > next && (
									<Button
										size="lg"
										color={Colors['border-gray-4']}
										label={t('button.more-jobs') as string}
										className="rounded-pill"
										onClick={handleMore}
									/>
								)}
								{data && data?.totalItems > 0 && (
									<Button
										size="lg"
										className="gap-3 rounded-pill"
										onClick={handleNavigate}>
										<FontAwesome
											icon="plus"
											size="xl"
											swapOpacity
										/>
										{t('title.add-new-internship')}
									</Button>
								)}
							</div>
							{data &&
								data?.items?.length === 0 &&
								internships && (
									<ProfileAddNewCard
										navigationUrl={addInternshipUrl}
										title="title.add-new-internship"
									/>
								)}
						</Collapsible>
					</div>
				)}
				{!internships?.enabled && (
					<div className="overlay overlay_white overlay-box" />
				)}

				<ModalBoxSectionEditProfile
					isOpen={openModal}
					onClose={toggleModal}
					title="info.internships-title"
					description="info.internships-description"
				/>
			</Container>
		</section>
	);
};
