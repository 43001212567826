import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCurrentLocale } from 'Utils';
import { PageHeadingSearch, ReactSelect } from 'Elements';

interface Props {
	totalItem?: number;
	onSubmit?: (keyword: string) => void;
	onchangeRegion?: (regionCode: string) => void;
	optionsRegion?: any;
	regionCode?: any;
}

export const CreateSubscriptionSearchCommunityPartner: FC<Props> = ({
	onSubmit,
	totalItem = 0,
	optionsRegion,
	regionCode,
	onchangeRegion
}) => {
	const { t } = useTranslation();
	const [regionValue, setRegionValue] = useState<string>();

	return (
		<div className="d-flex align-items-center gap-3 flex-wrap border-bottom pb-4">
			<div className="d-flex flex-wrap flex-column flex-md-row align-items-center gap-3 w-md-70">
				<PageHeadingSearch onSubmit={onSubmit} />
				<ReactSelect
					className="w-100 w-md-30"
					getOptionLabel={option => `${option?.label}XXX`}
					value={
						regionValue 
							? regionValue
							: regionCode
							? regionCode
							: { label: t('text.any-region'), value: '' }
					}
					options={optionsRegion as []}
					onChange={(e) => {
						onchangeRegion &&
							onchangeRegion(e.value === '' ? '' : e.label);
						setRegionValue(e as string);
					}}
				/>
			</div>
			{/* <Button color={Colors['primary']} onClick={() => {}}>
				{t('menu.search')}
			</Button> */}
			{totalItem > 0 && (
				<div className="ms-auto d-flex align-items-center gap-1 text-gray-4 fw-300">
					<span className="fw-500 text-gray-5">
						{new Intl.NumberFormat(getCurrentLocale().lang).format(
							totalItem
						)}
					</span>
					{t('title.items')}
				</div>
			)}
		</div>
	);
};
