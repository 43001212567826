import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { containerVariants, names } from 'Constants';
import {
	useAxios,
	useBusinessPartnerInvoice,
} from 'Hooks';
import { BadgeStatus, Colors, PaymentList } from 'Types';
import {
	api,
	clx,
	CommunityPartnerCreditStatus,
	currencyFormatter,
	formatLocaleDateTime,
	invoiceStatus,
	unslug
} from 'Utils';
import {
	DataTable,
	DataTableBody,
	DataTableHead,
	DataTableLoading,
	DataTableRow,
	DataTableRowCell,
	NoData,
	Pagination,
	ListingPage,
	Button,
	EndOfList,
	SystemErrorAlert,
	Badge,
	FontAwesome
} from 'Elements';
import styles from 'partials/page/transaction.module.scss';

export const ListInvoice = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { invoices } = names;
	const { sendRequest } = useAxios<string>();
	const [searchParams, setSearchParams] = useSearchParams();
	const params = Object.fromEntries([...searchParams]);
	const pageParam = searchParams.get('page') || 1;
	const queryParam = searchParams.get('q') || '';
	const statusParam = unslug(searchParams.get('status') ?? '') ?? '';
	const [newData, setNewData] = useState<PaymentList[]>([]);
	const [state, setState] = useState({
		pageSize: 20,
		totalItems: 0,
		currentPage: pageParam,
		keyword: queryParam,
		filterBy: statusParam
	});

	const getFilterByName = (filter_name: string) => {
		return invoiceStatus.find(
			(filter) =>
				filter.title?.toLowerCase() === filter_name?.toLowerCase()
		);
	};

	const getStatusData = (status) => {
		return CommunityPartnerCreditStatus.find(
			(elem) => elem.value === status
		);
	};

	const { data, isError, isFetching } = useBusinessPartnerInvoice(
		{
			pageSize: state.pageSize,
			pageNumber: state.currentPage,
			keyword: state.keyword,
			subscriptionId: '',
			reason: ''
		},
		{
			onSuccess: (data) => {
				setState((prev) => ({
					...prev,
					totalItems: data.totalItems
				}));
			}
		}
	);

	const onChangePageSize = (pageSize: number) => {
		setSearchParams({ ...params, page: '1' });
		setState((prev) => ({
			...prev,
			pageSize,
			currentPage: 1
		}));
	};

	const handleSearch = (query: string) => {
		setSearchParams({
			...params,
			page: '1',
			q: query
		});
		setState((prev) => ({
			...prev,
			currentPage: 1,
			keyword: query
		}));
	};

	const handleFilterBy = (selected_filter: Partial<BadgeStatus>) => {
		setSearchParams({
			...params,
			page: '1',
			status: selected_filter.title?.toString() ?? ''
		});
		setState((prev) => ({
			...prev,
			filterBy: selected_filter.label?.toString() ?? '',
			currentPage: 1
		}));
	};

	const handlePageChange = (page: number) => {
		setSearchParams({ ...params, page: page.toString() });
		setState((prev) => ({
			...prev,
			currentPage: page
		}));
	};

	const redirectTo = (id: string) => {
		void navigate(`${invoices.path}/${id}`, {
			state: { prevPath: `${invoices.path}` }
		});
	};

	const cellClassName = (col: string) => {
		const cols: { [x: string]: string } = {
			'invoice-number': 'w-sm-15',
			product: 'w-sm-20 fw-500',
			'created-at': 'w-sm-20',
			amount: 'w-sm-15',
			status: 'w-sm-15',
			operation: 'w-sm-15'
		};
		return clx('w-100', cols[col.toLowerCase()]);
	};

	const getPlan = async (subscriptionId) => {
		let x = '';
		await sendRequest(
			api.getSubscriptionDetail(subscriptionId),
			{ method: 'get' },
			(data: any) => {
				x = data?.subscriptionPlanInfo?.name;
			}
		);
		return x;
	};

	const getNamePlan = (subscriptionId) => {
		return getPlan(subscriptionId);
	};

	useEffect(() => {
		if (data) {
			const fetchData = async () => {
				const plans: any = await Promise.all(
					data?.items?.map(async (transaction) => {
						if (transaction?.invoiceInfo) {
							const planName = await getNamePlan(
								transaction?.subscriptionId
							);
							return { ...transaction, planName };
						}
						return transaction;
					})
				);
				setNewData(plans);
			};

			fetchData();
		}
	}, [data]);

	return (
		<ListingPage
			totalItems={data?.totalItems}
			pageSizes={[10, 15, 20, 50]}
			selectedPageSize={state.pageSize}
			onPageSizeChange={onChangePageSize}
			filterOptions={invoiceStatus}
			selectedFilter={t(
				getFilterByName(statusParam)?.label ?? 'dropdown.all'
			)}
			onFilterChange={handleFilterBy}
			onSearchChange={handleSearch}>
			<DataTable>
				{state?.totalItems > 0 && (
					<DataTableHead>
						<DataTableRowCell
							cellText="label.invoice-number"
							cellClassName={cellClassName('invoice-number')}
						/>
						<DataTableRowCell
							cellText="table.product"
							cellClassName={cellClassName('product')}
						/>
						<DataTableRowCell
							cellText="table.created-at"
							cellClassName={cellClassName('created-at')}
						/>
						<DataTableRowCell
							cellText="table.amount"
							cellClassName={cellClassName('amount')}
						/>
						<DataTableRowCell
							cellText="table.status"
							cellClassName={cellClassName('status')}
						/>
						<DataTableRowCell
							cellText="table.operation"
							cellClassName={cellClassName('operation')}
						/>
					</DataTableHead>
				)}
				<DataTableBody>
					{!isFetching && (
						<motion.div
							variants={containerVariants}
							initial="hidden"
							animate="show">
							{newData?.map((transaction) => {
								return (
									<DataTableRow key={transaction?.id}>
										<DataTableRowCell
											cellClassName={cellClassName(
												'invoice-number'
											)}
											cellHead="table.invoice-number"
											cellText={
												transaction?.invoiceInfo
													?.invoiceNumber
											}
										/>
										<DataTableRowCell
											cellClassName={cellClassName(
												'product'
											)}
											cellHead="table.product">
											{transaction?.planName}
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName={cellClassName(
												'created-at'
											)}
											cellHead="table.created-at">
											<time
												title={formatLocaleDateTime(
													transaction?.createdAt
												)}
												dateTime={formatLocaleDateTime(
													transaction?.createdAt
												)}>
												{formatLocaleDateTime(
													transaction?.createdAt
												)}
											</time>
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName={cellClassName(
												'amount'
											)}
											cellHead="table.amount">
											<div className="d-flex flex-column text-end text-sm-start">
												<strong
													className="fs-base"
													title={currencyFormatter(
														transaction?.price
															?.totalAmount
													)}>
													{currencyFormatter(
														transaction?.price
															?.totalAmount
													)}
												</strong>
												<span className="text-gray-3 lh-base fs-small">
													{t('label.tax-included')}
												</span>
											</div>
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName={cellClassName(
												'status'
											)}
											cellHead="table.status">
											<Badge
												color={
													getStatusData(
														transaction?.status
													)?.color ?? Colors.primary
												}>
												{t(
													`${
														getStatusData(
															transaction?.status
														)?.label
													}`
												)}
											</Badge>
										</DataTableRowCell>
										<DataTableRowCell
											cellHead="table.operation"
											cellClassName={cellClassName(
												'operation'
											)}
											boxClassName="d-flex flex-wrap gap-2">
											<Button
												size="sm"
												color={Colors['white-primary']}
												label={
													t(
														'button.details'
													) as string
												}
												onClick={() =>
													redirectTo(transaction.id)
												}
											/>
											{transaction?.invoiceInfo
												?.invoiceDocumentUrl && (
												<a
													href={
														transaction?.invoiceInfo
															?.invoiceDocumentUrl
													}
													rel="noreferrer"
													target="_blank"
													className={styles.button}
													download={`jobbiplace-invoice_${transaction?.invoiceNumber}.pdf`}>
													<FontAwesome
														icon="download"
														size="lg"
													/>
												</a>
											)}
										</DataTableRowCell>
									</DataTableRow>
								);
							})}
						</motion.div>
					)}
					{isFetching && (
						<DataTableLoading
							widths={[15, 20, 20, 15, 15, 15]}
							count={state.pageSize}
						/>
					)}
				</DataTableBody>
				{isError && <SystemErrorAlert />}
				{!isFetching && data?.totalItems === 0 && (
					<NoData message="title.no-invoice" />
				)}
			</DataTable>
			{!isFetching && <EndOfList data={data} />}
			{!isFetching && data && data?.totalPages > 1 && (
				<Pagination
					className="mt-3"
					totalItemsCount={data?.totalItems}
					activePage={parseInt(state.currentPage.toString(), 10)}
					itemsCountPerPage={state.pageSize}
					onChange={handlePageChange}
				/>
			)}
		</ListingPage>
	);
};
