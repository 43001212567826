import { FC } from 'react';
import styled from 'styled-components';
import { darken } from 'polished';
import { useNavigate } from 'react-router-dom';
import { EditButton } from 'Elements';
import styles from 'partials/homepage/card/staff-card.module.scss';

interface Props {
	name: string;
	position: string;
	image: string;
	id: string;
	preview?: boolean;
	staffType: 'members' | 'employees';
}

const Image = styled.img<{
	profileType: 'communityPartner' | 'businessPartner';
}>`
	${({ theme, profileType }) =>
		theme[profileType].primaryColor &&
		`border-color: ${theme[profileType].primaryColor};`}
`;

const Position = styled.span<{
	profileType: 'communityPartner' | 'businessPartner';
}>`
	${({ theme, profileType }) =>
		theme[profileType].primaryColor &&
		`background-color: ${darken(0.05, theme[profileType].primaryColor)}`}
`;

export const PreviewProfileStaffCard: FC<Props> = ({
	name,
	position,
	image,
	id,
	preview = true,
	staffType
}) => {
	const navigate = useNavigate();
	const defaultImage = '/assets/img/default-image.png';
	const profileType =
		staffType === 'members' ? 'communityPartner' : 'businessPartner';

	const handleNavigate = () => {
		navigate(`/${staffType}/${id}`, {
			state: {
				prevPath: '/home'
			}
		});
	};

	return (
		<article className={styles.card} onClick={handleNavigate}>
			<div className={styles.imageWrapper}>
				<Image
					src={image || defaultImage}
					alt={name}
					className={styles.image}
					profileType={profileType}
				/>
				<Position profileType={profileType} className={styles.position}>
					{position}
				</Position>
			</div>
			<h3 className="text-gray-5 fs-6 fw-bold mt-2">{name}</h3>
			{!preview && (
				<div className="edit-overlay">
					<EditButton className="edit-overlay-icon" />
				</div>
			)}
		</article>
	);
};
