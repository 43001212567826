import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
	useAxios,
	useModal,
	usePaymentMethodList,
	useSubscription,
	useSubscriptionPayments
} from 'Hooks';
import { config } from 'src/helpers/Config';
import { api, currencyFormatter } from 'Utils';
import { Colors, SubscriptionCalculate } from 'Types';
import { Button, FontAwesome, ModalBoxPaymentResult } from 'Elements';
import styles from 'partials/step/step-container.module.scss';

interface Props {
	onPrev: () => void;
	goToStep: (stepId: string) => void;
}

export const UpgradeSubscriptionPaymentMethod: FC<Props> = ({ goToStep }) => {
	const navigate = useNavigate();
	const { subscription_id } = useParams();
	const { t } = useTranslation();
	const { sendRequest: subscriptionPaymentsDetail } = useAxios<any>();
	const { sendRequest, isLoading } = useAxios<any>();
	const [searchParams, setSearchParams] = useSearchParams();
	const [loading, setLoading] = useState(true);
	const [subscriptionPaymentStatus, setSubscriptionPaymentStatus] =
		useState(-1);
	const [disabled, setDisabled] = useState(false);
	const [intervalDelay, setIntervalDelay] = useState(5000);
	// const [retryNumber, setRetryNumber] = useState(0);
	const [subscriptionCalculate, setSubscriptionCalculate] =
		useState<SubscriptionCalculate>();
	const [communityPartnerId, setCommunityPartnerId] = useState();
	const UpgradeSubscriptionPlanId =
		searchParams.get('UpgradeSubscriptionPlanId') || '';
	const CurrentSubscriptionPlanId =
		searchParams.get('CurrentSubscriptionPlanId') || '';
	const voucherCode = searchParams.get('voucherCode') || '';

	const res: any = useSubscription(subscription_id as string, {
		enabled: !!subscription_id
	});

	const { data: subscriptionPayments, isLoading: loadingLabel } =
		useSubscriptionPayments(res?.data?.upgradeSubscriptionPaymentId ?? '', {
			enabled: !!res?.data?.upgradeSubscriptionPaymentId
		});
	const [subscriptionPlanId, setSubscriptionPlanId] = useState();
	const planType = searchParams.get('planType') || '';

	const showModal = searchParams.get('showModal') || 'false';
	const [openModal, toggleModal] = useModal();

	const { data: paymentMethodList, isLoading: isLoadingPaymentMethodList } =
		usePaymentMethodList();

	useEffect(() => {
		setCommunityPartnerId(
			res?.data?.communityPartnerInfo?.communityPartnerId ??
				searchParams.get('communityPartnerId')
		);
		setSubscriptionPlanId(
			res?.data?.subscriptionPlanInfo?.subscriptionPlanId ??
				searchParams.get('CurrentSubscriptionPlanId')
		);
	}, [res]);

	useEffect(() => {
		if (communityPartnerId && CurrentSubscriptionPlanId) {
			setLoading(true);
			sendRequest(
				api.getSubscriptionCalculateUpgrade(
					communityPartnerId,
					CurrentSubscriptionPlanId,
					UpgradeSubscriptionPlanId,
					voucherCode ?? '',
					Number(planType) as number
				),
				{ method: 'GET' },
				(subscriptionCalculate) => {
					setSubscriptionCalculate(subscriptionCalculate);
					setLoading(false);
				}
			);
		}
	}, [communityPartnerId, subscriptionPlanId]);

	const checkPayStatus = async () => {
		if (res?.data?.upgradeSubscriptionPaymentId) {
			await subscriptionPaymentsDetail(
				api.getSubscriptionPaymentsDetail(
					res?.data?.upgradeSubscriptionPaymentId
				),
				{ method: 'GET' },
				(data) => {
					if (data?.status) {
						setSubscriptionPaymentStatus(data?.status);
					}
				}
			);
		}
	};

	useEffect(() => {
		if (subscriptionPaymentStatus !== -1) {
			const intervalId = setInterval(() => {
				setIntervalDelay((prevDelay) => prevDelay + 2000);
				// setRetryNumber((prevDelay) => prevDelay + 1);
				checkPayStatus();
			}, intervalDelay);
			if (subscriptionPaymentStatus !== 3) {
				clearInterval(intervalId);
			}
			return () => clearInterval(intervalId);
		} else {
			checkPayStatus();
		}
	}, [
		subscriptionPaymentStatus,
		intervalDelay,
		res?.data?.upgradeSubscriptionPaymentId
	]);

	const onClickPayment = async (paymentMethodId) => {
		setDisabled(true);
		setSearchParams({
			showModal: 'true',
			step: 'step3',
			planType: planType,
			voucherCode: voucherCode,
			UpgradeSubscriptionPlanId: UpgradeSubscriptionPlanId,
			CurrentSubscriptionPlanId: CurrentSubscriptionPlanId,
			SubscriptionId: subscription_id?.toString() as string
		});
		toggleModal();
		await sendRequest(
			api.subscriptionPay(res?.data?.upgradeSubscriptionPaymentId),
			{ data: { paymentMethodId: paymentMethodId } },
			async () => {
				setSubscriptionPaymentStatus(3);
				// checkPayStatus();
			}
		)
			.catch(() => {
				toggleModal();
				setSearchParams({
					showModal: 'false',
					step: 'step3',
					planType: planType,
					voucherCode: voucherCode,
					UpgradeSubscriptionPlanId: UpgradeSubscriptionPlanId,
					CurrentSubscriptionPlanId: CurrentSubscriptionPlanId,
					SubscriptionId: subscription_id?.toString() as string
				});
			})
			.finally(() => {
				setDisabled(true);
			});
	};

	const onClickAddPayment = async () => {
		setDisabled(true);
		const returnUrl: any = `${config.redirectUrl}subscriptions/${subscription_id}/upgrade?step=step3&communityPartnerId=${communityPartnerId}&CurrentSubscriptionPlanId=${CurrentSubscriptionPlanId}&UpgradeSubscriptionPlanId=${UpgradeSubscriptionPlanId}&planType=${planType}&`;
		setDisabled(true);
		await sendRequest(
			api.addPaymentMethod,
			{ data: { returnUrl: returnUrl } },
			async (data: any) => {
				window.location = data;
				setDisabled(false);
			}
		);
	};

	return (
		<div className={styles.form}>
			<div className="d-flex flex-column px-3">
				<h2 className="fs-base fw-500 mb-4">
					{t('text.choose-your-payment-method')}
				</h2>
				<div className="d-flex flex-column gap-1">
					<span className="fs-sm fw-400">
						{t('text.total-price')}
					</span>
					<span className="fs-small fw-400">
						{t(
							'text.with-this-subscription-you-can-support-other-community-partners'
						)}
					</span>
				</div>
				<div className="my-4 pb-3 border-bottom">
					<div className="d-flex align-items-center justify-content-between">
						<h5 className="h5 mb-0">{t('title.total')}</h5>
						<div className="d-flex flex-column text-end">
							{!loading && !loadingLabel && (
								<div>
									<h5
										className="h5 mb-0"
										style={{
											textDecoration: 'line-through',
											color: '#8D8DA0'
										}}>
										{currencyFormatter(
											res?.data?.subscriptionPlanInfo
												?.price +
												subscriptionPayments?.price
													?.totalAmount
										)}
									</h5>
									<h5 className="h5 mb-0">
										{currencyFormatter(
											subscriptionPayments?.price
												?.totalAmount ?? 0
										)}
									</h5>
								</div>
							)}
							{loading && loadingLabel && (
								<>
									<div className={styles.labelSkeleton}></div>
									<div className={styles.labelSkeleton}></div>
								</>
							)}
						</div>
					</div>
				</div>
				<div className="d-flex flex-column gap-4">
					{!isLoadingPaymentMethodList &&
						paymentMethodList?.map((item) => {
							return (
								<div
									key={item.paymentMethodId}
									className={
										isLoading || disabled
											? styles.disabledCardPayment
											: styles.cardPayment
									}>
									<div className="d-flex align-items-center justify-content-between">
										<div className="d-flex flex-column gap-2">
											<div className="d-flex align-items-center gap-2">
												<FontAwesome
													icon={'credit-card'}
													className={
														isLoading || disabled
															? 'text-gray-2'
															: 'text-primary'
													}
													size="1x"
													swapOpacity
												/>
												<span
													className={`fs-5 fw-500 ${
														isLoading || disabled
															? 'text-gray-2'
															: 'text-gray-4'
													}`}>
													{`${item.title}  ${
														item.last4 ?? ''
													}`}
												</span>
											</div>
											<div className="mt-2">
												{item.expYear &&
													item.expYear !== '0' && (
														<span
															className={`fw-400 fs-sm 	${
																isLoading ||
																disabled
																	? 'text-gray-2'
																	: 'text-gray-3'
															} mx-3`}>
															{`Expired ${item.expMonth}/${item.expYear}`}
														</span>
													)}
												{item.expYear === '0' && (
													<span className="fw-400 fs-sm text-gray-3 mx-3">
														{item.email}
													</span>
												)}
											</div>
										</div>
										<div>
											<Button
												onClick={() => {
													if (
														!disabled &&
														!isLoading
													) {
														onClickPayment(
															item.paymentMethodId
														);
													}
												}}
												color={
													disabled || isLoading
														? Colors['gray-2']
														: Colors[
																'white-primary'
														  ]
												}>
												{t('button.pay-with-this')}
											</Button>
										</div>
									</div>
								</div>
							);
						})}
					{isLoadingPaymentMethodList &&
						[1, 2, 3].map((item) => {
							return (
								<div
									key={item}
									className={
										styles.cardPaymentSkeleton
									}></div>
							);
						})}
					{!loading && !isLoadingPaymentMethodList && (
						<div
							className={
								disabled || isLoading
									? styles.disabledCardPayment
									: styles.addCardPayment
							}
							onClick={onClickAddPayment}>
							<div className="d-flex align-items-center gap-2">
								<FontAwesome
									icon="plus"
									className={
										disabled || isLoading
											? 'text-gray-2'
											: 'text-primary'
									}
									size="1x"
									swapOpacity
								/>
								<span
									className={`fs-5 fw-500  ${
										disabled || isLoading
											? 'text-gray-2'
											: 'text-primary'
									}`}>
									{t('button.add-payment-method')}
								</span>
							</div>

							<div className="mt-2">
								<span
									className={`fw-400 fs-sm mx-3 ${
										disabled || isLoading
											? 'text-gray-2'
											: 'text-gray-3'
									}`}>
									{t('button.add-new-payment-method')}
								</span>
							</div>
						</div>
					)}
				</div>
				<div className="my-3 d-flex justify-content-center">
					<Button
						onClick={() => {
							navigate(
								`/subscriptions/${subscription_id}/upgrade?step=step2&SubscriptionId=${subscription_id}&CurrentSubscriptionPlanId=${CurrentSubscriptionPlanId}&UpgradeSubscriptionPlanId=${UpgradeSubscriptionPlanId}&communityPartnerId=${communityPartnerId}&voucherCode=${
									voucherCode ?? ''
								}&planType=${planType}`
							);
							goToStep('step2');
						}}
						color={
							disabled || isLoading
								? Colors['gray-2']
								: Colors['white-primary']
						}>
						{t('button.back')}
					</Button>
				</div>
			</div>
			{res?.data && (
				<ModalBoxPaymentResult
					subscriptionId={subscription_id as string}
					subscriptionCalculate={
						subscriptionCalculate as SubscriptionCalculate
					}
					subscriptionPaymentStatus={
						res?.data?.upgradeSubscriptionPaymentId
							? subscriptionPaymentStatus
							: 1
					}
					isOpen={showModal === 'true' ? true : openModal}
					onClose={toggleModal}
				/>
			)}
		</div>
	);
};
