import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Drawer from 'react-modern-drawer';
import { Button, FontAwesome, LoadingSpinner } from 'src/containers/Elements';
import { Colors } from 'src/types/Color';
import styles from './find-partner.module.scss';
import PartnerCard from './PartnerCard';
import { useDebounce } from 'src/hooks/useDebounce';
import { useInfiniteQuery } from 'react-query';
import { queryKey } from 'src/constants/QueryKey';
import { fetch } from 'src/helpers/Fetcher';
import InfiniteScroll from 'react-infinite-scroller';

interface Props {
	isOpen: boolean;
	toggleDrawer: () => void;
	isPremium: boolean;
}

const FindPartners: FC<Props> = ({
	isOpen,
	toggleDrawer,
	isPremium = false
}) => {
	const { t } = useTranslation();
	const [keyword, setKeyword] = useState('');
	const debouncedSearchTerm = useDebounce(keyword, 1000);
	const {
		data: communityPartner,
		isFetching,
		fetchNextPage,
		refetch,
		hasNextPage,
		isError
	} = useInfiniteQuery(
		[queryKey.communityPartners],
		async ({ pageParam }) => {
			return await fetch.communityPartners(
				10,
				pageParam || 1,
				debouncedSearchTerm,
				'',
				isPremium
			);
		},
		{
			getNextPageParam: (lastPage, pages) => {
				if (lastPage.pageNumber < lastPage.totalPages) {
					return pages.length + 1;
				}
			},
			enabled: isOpen
		}
	);
	// //Todo: Implement infinite scroll
	// const { data: communityPartner, isFetching } = useCompanyCommunityPartner(
	// 	{
	// 		pageSize: 200,
	// 		pageNumber: 1,
	// 		keyword: debouncedSearchTerm,
	// 		regionCode: '',
	// 		IsPremium: isPremium
	// 	},
	// 	{
	// 		enabled: isOpen
	// 	}
	// );

	const handelKeywordChange = (e) => {
		setKeyword(e.target.value);
	};

	return (
		<Drawer
			style={{
				overflowY: 'hidden'
			}}
			open={isOpen}
			onClose={toggleDrawer}
			lockBackgroundScroll
			zIndex={200}
			direction="right"
			size="28rem">
			<div className="border-bottom border-1 border-gray-2">
				<div className="d-flex justify-content-between gap-2 p-3 align-items-center">
					<span className="fs-6 fw-semibold">
						{t('button.find-community-partners')}
					</span>
					<div>
						<Button
							color={Colors['gray-7']}
							size="md"
							onClick={toggleDrawer}>
							<FontAwesome
								icon="close"
								size="lg"
								className="text-gray-3"
							/>
							<div className="mx-2 text-gray-3">{t('Close')}</div>
						</Button>
					</div>
				</div>
			</div>

			<div className="border-bottom border-1 border-gray-2 p-2">
				<form noValidate className="d-flex w-100 gap-2 ">
					<input
						onChange={handelKeywordChange}
						type="text"
						placeholder={t('text.search-community-partners')}
						className="inputbox w-100"
					/>
				</form>
			</div>
			<div className={styles['drawer-body']}>
				<InfiniteScroll
					hasMore={hasNextPage}
					useWindow={false}
					loader={
						<div className="text-center" key={0}>
							<LoadingSpinner />
						</div>
					}
					loadMore={() => fetchNextPage()}>
					<div className="d-flex flex-column gap-4">
						{communityPartner?.pages.map((page) =>
							page?.items?.map((partner) => (
								<PartnerCard
									toggleDrawer={toggleDrawer}
									partner={partner}
									key={partner.id}
									isPremium={isPremium}
								/>
							))
						)}
					</div>
				</InfiniteScroll>
			</div>

			{/* {isFetching && <LoadingContent title="loading..." />} */}
		</Drawer>
	);
};

export default FindPartners;
