import { Button } from 'Elements';
import JobPartners from './job-partners';
import PremiumPartners from './premium-partners';
import Locations from './locations';
import styles from './visibility-setting.module.scss';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function VisibilitySetting() {
	const { t } = useTranslation();
	const [selectedTab, setSelectedTab] = useState(0);
	return (
		<>
			<div className="d-flex align-items-center gap-2 mb-3">
				<Button
					onClick={() => setSelectedTab(0)}
					className={
						selectedTab === 0
							? `bg-primary text-white`
							: `bg-white text-gray-3`
					}>
					{t('label.job-partners')}
				</Button>
				<Button
					onClick={() => setSelectedTab(1)}
					className={
						selectedTab === 1
							? `bg-primary text-white`
							: `bg-white text-gray-3`
					}>
					{t('label.locations')}
				</Button>
			</div>
			<div className={styles.wrapper}>
				{selectedTab === 0 ? (
					<>
						<JobPartners />
						<PremiumPartners />
					</>
				) : (
					<Locations />
				)}
			</div>
		</>
	);
}
