import { Colors, SelectOption } from 'Types';

export interface GeoAddress {
	address: {
		buildingNumber: string;
		country: string;
		countryCode: string;
		countryCodeISO3: string;
		countryIso2: string;
		countryName: string;
		countrySecondarySubdivision: string;
		countrySubdivision: string;
		countrySubdivisionName: string;
		countryTertiarySubdivision: string;
		crossStreet: string;
		extendedPostalCode: string;
		freeformAddress: string;
		municipality: string;
		municipalitySubdivision: string;
		postalCode: string;
		street: string;
		streetName: string;
		streetNameAndNumber: string;
		streetNumber: string;
	};
	id: string;
	position: { lat: number; lon: number };
	type: string;
}

export interface IBadgeStatus {
	value: string | number | null;
	title?: string;
	label: string;
	color: Colors;
	type?: string[];
}

export enum ResponsiveSizeGeoLocation {
	'sm' = 'sm',
	'md' = 'md',
	'lg' = 'lg',
	'xl' = 'xl'
}

export interface IMap {
	country?: string;
	distance?: number;
	keyword?: string;
	tenantId?: string;
	name?: string;
	state?: string;
	city?: string;
	postalCode?: string;
	street?: string;
	houseNo?: string;
	sortType?: string;
	lon?: number;
	lat?: number;
	pageSize?: number;
	pageNumber?: number;
	type?: number;
	mainRegion?: string;
	alternativeRegion?: string;
}

export interface Address {
	name: string;
	country: string;
	countryName: string;
	countryIso2: string;
	countryIso3: string;
	state: string;
	city: string;
	postalCode: string;
	street: string;
	houseNo: string;
	houseNumber: string;
	location: Location;
}

export interface Location {
	longitude: number;
	latitude: number;
}

export interface Country {
	id: string;
	name: string;
	states: State[];
}

export interface State {
	id: string;
	name: string;
	country: string;
}

export interface SelectableAddress extends Omit<Address, 'country' | 'state'> {
	country: SelectOption | null ;
	state: SelectOption | null ;
}

export interface SelectableCountry extends SelectOption {
	states: SelectableState[];
}

export interface SelectableState extends SelectOption {
	country?: string;
}
