import { Container } from 'reactstrap';
import { LoadingSpinner, Carousel, ProfileDepartmentCard } from 'Elements';
import styles from 'partials/homepage/section/community-partner-department-section.module.scss';
import { useDepartments } from 'src/hooks/useDepartment';
import { clx } from 'src/helpers/Utils';
import { Autoplay, EffectFade, Pagination, Thumbs } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useResponsive } from 'src/hooks/useResponsive';
import { Title } from 'src/containers/Styles';
import { useState } from 'react';
import './CommunityPartnerPreviewDepartmentSection.css';
import { Logo } from 'src/types/BusinessPartner';

type Props = {
	isLoadingProfile: boolean;
	departments: {
		enabled: boolean;
		headline: string;
	};
	header: {
		description: string;
		headerImage: Logo;
		headerTitle: string;
		logo: Logo;
		slogan: string;
		themeColor: number | string;
	};
};

export const CommunityPartnerPreviewDepartmentSection = ({
	isLoadingProfile,
	departments,
	header
}: Props) => {
	const { isDesktopAndBelow } = useResponsive();
	const [thumbsSwiper, setThumbsSwiper] = useState<any>();
	const { data } = useDepartments({
		pageSize: 20,
		pageNumber: 1,
		keyword: ''
	});

	return data?.totalItems !== 0 ? (
		<section className="position-relative mb-5 mt-6">
			<Container>
				{isLoadingProfile && (
					<div className="d-flex align-items-center justify-content-center">
						<LoadingSpinner />
					</div>
				)}
				{!isLoadingProfile && (
					<>
						<Title
							textColor="text-gray-5"
							className="h3 fw-700 mb-4 text-center">
							{departments?.headline ?? ''}
						</Title>
						{data && data?.totalItems > 0 && (
							<div className={styles.boxSwiper}>
								<Carousel
									loop
									effect="fade"
									followFinger={false}
									direction={
										isDesktopAndBelow
											? 'horizontal'
											: 'vertical'
									}
									slidesPerView={1}
									speed={800}
									autoplay={{
										delay: 3500,
										pauseOnMouseEnter: true,
										disableOnInteraction: false
									}}
									autoHeight={isDesktopAndBelow}
									pagination={{
										enabled: data.totalItems > 1,
										clickable: true,
										horizontalClass: `${styles.bullets}`,
										verticalClass: `${styles.bullets}`,
										bulletClass: `${styles.bullet} department-bullet`,
										bulletActiveClass: `${styles.active}`
									}}
									modules={[
										Pagination,
										EffectFade,
										Autoplay,
										Thumbs
									]}
									thumbs={{ swiper: thumbsSwiper }}
									className={clx(
										styles.carousel,
										'department-container'
									)}
									enabled={data.totalItems > 1}>
									{data.items
										?.reverse()
										?.map((department) => (
											<SwiperSlide
												key={department.id}
												className={styles.slide}>
												<ProfileDepartmentCard
													preview={true}
													key={department.id}
													department={department}
												/>
											</SwiperSlide>
										))}
								</Carousel>
								<Swiper
									onSwiper={setThumbsSwiper}
									loop
									spaceBetween={10}
									slidesPerView={5}
									freeMode={true}
									watchSlidesProgress={true}
									modules={[
										Pagination,
										EffectFade,
										Autoplay,
										Thumbs
									]}>
									{data.items?.map((department) => (
										<SwiperSlide
											key={department?.id}
											className={styles?.boxThumbs}>
											<img
												src={
													department?.image?.thumbnail
												}
											/>
											<div
												className={clx(
													'boxTitleThumbs',
													styles.boxTitleThumbs
												)}>
												{department?.title}
											</div>
										</SwiperSlide>
									))}
								</Swiper>
							</div>
						)}
					</>
				)}
			</Container>
		</section>
	) : (
		<></>
	);
};
